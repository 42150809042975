import React, { useState, useEffect } from 'react'
import { RotateLoader, DatePicker, Input, InputAddress, Switch, CommonText, Button } from '@lib/components'
import {
    SettingsFormGroup,
    SettingsFormGroupInput,
    SettingsFormInputContainer,
    SettingsFormLabelContainer,
    SettingsFormLabelLeftContainer,
    SettingsFormLabelRightContainer,
    SettingsCustomOption,
    SettingsCustomOptionsContainer,
    SalesTaxesAddButton,
    OpeningHoursContainer,
    OpeningHoursCard,
    OpeningHoursCardScheduleWrapper,
    OpeningHoursCardWrapper,
    MobileModal,
    MobileModalBody,
    MobileModalContent,
    MobileModalFooter,
    MobileModalHeader,
    SettingsSwitchContainer,
    SettingsActionButtonWrapper,
    OptionalText,
    OpeningHoursCardDatesWrapper,
    SettingsActionButtonContainer,
    FlexWrapper
} from "."
import {
    IconHelp,
    IconCheck,
    IconHome,
    IconPlusRed,
    IconHour,
    IconClose,
    IconGreenEllipse,
    IconRedEllipse,
    IconCalendar,
    IconCalendarAlt
} from '../../../../components/v2/icons'
import { PromoCardStatus } from './promo-codes'
import { RestaurantForm } from "../../../../../mobx/components/restaurant-form";
import { UI } from "../../../../../core/ui";
import { FastField } from "formik";
import { validators } from '@lib/common'
import { useStores } from '../../../../../mobx/useStores'
import { GoogleCreateService } from '../../../../../core/libs/google'
import { MapboxAPI } from '../../../../../core/libs/mapbox'
import { SpecialHourCard } from '../special-hours/special-hours-card'
import { SpecialHourModal, specialHourProps } from '../special-hours/special-hour-modal'
import { runOnceNotification } from '../../../../utils/run_once_notification'
import InputMask from 'react-input-mask';

type FormValues = T.Schema.Restaurant.RestaurantSchema["location"];

const TIMESLOTS = [
    { day: 'Monday', date: 'Mar 03, 2024', active: true, hours: '9:00 - 14:00' },
    { day: 'Tuesday', date: 'Mar 03, 2024', active: false, hours: '24 Hours' },
    { day: 'Wednesday', date: 'Mar 03, 2024', active: true, hours: '24 Hours' },
]

export const defaultOpeningHour = {
    day: 'Monday',
    open: '09:00',
    close: '21:00',
    h24: false
}
interface specialHourControlProps {
    selectedSpecialHour: specialHourProps | undefined;
    selectedIndex: number | undefined;
    loading: boolean;
    confirmDeletePopup: boolean;
    modalType: string;
}

export const SettingsLocation = () => {
    const [showModal, setShowModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [modal, setModal] = useState('opening')
    const store = useStores()
    const r = store.restaurant!
    let initialValues = r.location
    let GoogleService: any = {}
    useEffect(() => {
        GoogleService = GoogleCreateService();
    }, [])
    const [openingHour, setOpeningHour] = useState(defaultOpeningHour)
    const [openingHourIndex, setOpeningHourIndex] = useState(-1);
    const [specialHourControl, setSpecialHourControl] = useState<specialHourControlProps>({
        selectedIndex: undefined,
        selectedSpecialHour: undefined,
        modalType: '',
        loading: false,
        confirmDeletePopup: false,
    })
    const { selectedIndex, selectedSpecialHour, modalType, loading, confirmDeletePopup } = specialHourControl
    const specialHourList = store.restaurant?.location?.special_hours ? store.restaurant?.location?.special_hours : [];

    const handleOpenFormModal = (data: specialHourProps | undefined, index: number | undefined, modalType = 'add') => {
        setSpecialHourControl(prev => ({ ...prev, selectedSpecialHour: data, selectedIndex: index, modalType }));
    }
    const toggleDeletePopup = (index?: number) => {
        setSpecialHourControl(prev => ({ ...prev, confirmDeletePopup: !prev.confirmDeletePopup, selectedIndex: index }));
    }
    const handleDeleteSpecialHour = async () => {
        try {
            setSpecialHourControl(prev => ({ ...prev, loading: true }))

            if (selectedIndex !== undefined) {
                const response = await store.api.delete_special_hour(selectedIndex);
                if (response.outcome !== 0) {
                    UI.notification.error(response.message, { timeout: 6000 });
                }
                else {
                    UI.notification.success("Special hour deleted!");
                    store.deleteSpecialHour(selectedIndex)
                }
                setSpecialHourControl(prev => ({ ...prev, loading: false, selectedIndex: undefined }))

            }
            toggleDeletePopup();
        }
        catch (e) {
            UI.notification.error("Something went wrong, try again or contact us");
            setSpecialHourControl(prev => ({ ...prev, loading: false, selectedIndex: undefined }))
            toggleDeletePopup();
        }
    }

    return (
        <div style={{paddingTop: 16}}>
            <RestaurantForm<FormValues>
                submit={async (r, values) => {
                    r.location = values;
                    const update = { $set: { location: values } };
                    return { r, update };
                }}
                validators={{
                    address: (values) => {
                        const md = values.map_data;
                        if (md.type === "google_maps" || md.type === "osm") {
                            if (!values.address || !md.components || !md.lat || !md.lng) {
                                return { address: "Required - search and select your store address" };
                            }
                        }
                        else if (!values.address) {
                            return { address: "Required - enter your store address" };
                        }
                    },
                    opening_hours: (values) => {
                        //@ts-ignore
                        const { error } = validators.business_hours(values.opening_hours);
                        if (error) {
                            return { opening_hours: error };
                        }
                    },
                }}
                initialValues={initialValues}
                onError={() => UI.notification.error("An error occurred")}
                onSuccessMessage="Settings Updated"
            >
                {({ form, error, getFieldError }) => {
                    const { isSubmitting, setFieldValue } = form;
                    const mapType = form.values.map_data.type;
                    runOnceNotification(error)
                    return (<div style={{ paddingBottom: 83 }}>
                        <SettingsFormGroup>
                            <SettingsFormGroupInput className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Map Data Source</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsCustomOptionsContainer>
                                    <FastField
                                        name="map_data.type"
                                        render={({ field }: any) => (
                                            <>
                                                {[
                                                    { value: "osm", name: "Open Street Maps" },
                                                    { value: "google_maps", name: "Google Maps" },
                                                    { value: "custom", name: "None" },
                                                ].map((opt, index) => (
                                                    <SettingsCustomOption
                                                        key={index}
                                                        onClick={() => {
                                                            setFieldValue("address", "");
                                                            setFieldValue("map_data", { type: opt.value });
                                                        }}
                                                        className={field.value === opt.value ? 'active' : ''}>
                                                        {opt.name}
                                                        {field.value === opt.value && <IconCheck />}
                                                    </SettingsCustomOption>
                                                ))}
                                            </>
                                        )} />
                                </SettingsCustomOptionsContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput
                                error={(error && getFieldError(form, 'address')) || null}
                                className='flex-half'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Store Address</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer id="map-input">
                                    {(mapType === "google_maps" || mapType === "osm") && (
                                        <InputAddress
                                            type={mapType}
                                            gm={GoogleService}
                                            mb={MapboxAPI}
                                            value={form.values.address}
                                            onChange={(address, map_data) => {
                                                setFieldValue("address", address);
                                                setFieldValue("map_data", map_data);
                                            }}
                                            onError={() => { }}
                                        />
                                    )}
                                    {(mapType === "custom") && (
                                        <FastField
                                            name="address"
                                            render={({ field }: any) => (
                                                <input {...field} required={true} />
                                            )}
                                        />
                                    )}
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput className='flex-half'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Location Name (optional)</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="name"
                                        render={({ field }: any) => (
                                            <input {...field} />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput className='flex-half'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Phone Number (optional)</SettingsFormLabelLeftContainer>
                                    <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                </SettingsFormLabelContainer>
                                <SettingsFormInputContainer>
                                    <FastField
                                        name="phone"
                                        render={({ field }: any) => (
                                            <input {...field}
                                                type="tel"
                                                inputMode="numeric"
                                                onChange={(e) => {
                                                    const numericValue = e.target.value.replace(/\D/g, '');
                                                    form.setFieldValue('phone', numericValue);
                                                }}
                                                value={field.value} />
                                        )}
                                    />
                                </SettingsFormInputContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput
                                error={(error && getFieldError(form, 'opening_hours')) || null}
                                className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Opening Hours</SettingsFormLabelLeftContainer>
                                    <SalesTaxesAddButton type='button' onClick={() => setShowModal(!showModal)}>
                                        <IconPlusRed /> Add Time Slot
                                    </SalesTaxesAddButton>
                                </SettingsFormLabelContainer>
                                <OpeningHoursContainer>
                                    <p>Enter time in 24H format, e.g. 21:00 for 9:00pm. Ensure time slots do not overlap or close before they open</p>
                                    {form.values.opening_hours.map((times, index) => {
                                        return (
                                            <OpeningHoursCard onClick={() => {
                                                setEdit(true)
                                                setModal('opening')
                                                setShowModal(true)
                                                setOpeningHour(times)
                                                setOpeningHourIndex(index)
                                            }}
                                                key={index}>
                                                <OpeningHoursCardWrapper>
                                                    {times.day}
                                                    <OpeningHoursCardScheduleWrapper style={{ flex: 1 }}>
                                                        <IconHour />
                                                        {times.h24 ? '24 Hours' :
                                                            `${times.open} - ${times.close}`}
                                                    </OpeningHoursCardScheduleWrapper>
                                                </OpeningHoursCardWrapper>
                                            </OpeningHoursCard>
                                        )
                                    })}
                                </OpeningHoursContainer>
                            </SettingsFormGroupInput>
                            <SettingsFormGroupInput className='flex-full'>
                                <SettingsFormLabelContainer>
                                    <SettingsFormLabelLeftContainer>Special Hours <OptionalText>(Optional)</OptionalText></SettingsFormLabelLeftContainer>
                                    <SalesTaxesAddButton
                                        type="button"
                                        onClick={() => handleOpenFormModal(undefined, undefined, 'add')
                                        }>
                                        <IconPlusRed /> Add Special Hours
                                    </SalesTaxesAddButton>
                                </SettingsFormLabelContainer>
                                <OpeningHoursContainer>
                                    <p>Set special opening hours for holidays or other periods. This will override your regular opening hours for your store location and all services</p>
                                    {specialHourList.map((data, i) => {
                                        return (
                                            <SpecialHourCard key={i} data={data} index={i} selectedIndex={selectedIndex} openModal={(type: string) => handleOpenFormModal(data, i, type)} toggleDeletePopup={() => toggleDeletePopup(i)} />
                                        )
                                    })}
                                </OpeningHoursContainer>
                            </SettingsFormGroupInput>
                        </SettingsFormGroup>
                        <SettingsActionButtonContainer>
                            <SettingsActionButtonWrapper>
                                <button
                                    type="button"
                                    className='btn-cancel'
                                    onClick={() => store.setActiveMobileMenu(
                                        store.activeMobileMenu.referrer.pop()!,
                                        Array.from(new Set(store.activeMobileMenu.referrer))
                                    )}>
                                    Cancel
                                </button>
                                <button style={{cursor: 'pointer'}} name="setting_special_hour" type="button" disabled={isSubmitting} onClick={(e) => {
                                    if (e.currentTarget.name === 'setting_special_hour') {
                                        form.handleSubmit()
                                    }
                                }}>
                                    {isSubmitting && <RotateLoader size={2} color="white" />}
                                    {!isSubmitting && "Save"}
                                </button>
                            </SettingsActionButtonWrapper>
                        </SettingsActionButtonContainer>
                        {confirmDeletePopup && <MobileModal>
                            <MobileModalContent>
                                <CommonText size="small" weight={500} color={' #6B7270'}>
                                    Are you sure you want to delete this date(s)?
                                </CommonText>
                                <FlexWrapper>
                                    <Button type="button" color='primary' full={true} loading={loading ? true : false} onClick={handleDeleteSpecialHour}>
                                        Confirm
                                    </Button>
                                    <Button type="button" full={true} onClick={() => toggleDeletePopup()}>
                                        Cancel
                                    </Button>
                                </FlexWrapper>
                            </MobileModalContent>
                        </MobileModal>}
                        {showModal && <MobileModal>
                            <MobileModalContent>
                                <MobileModalHeader>
                                    {edit ? 'Edit' : 'Add'} Opening Hours
                                    <span onClick={() => {
                                        setShowModal(!showModal)
                                        setEdit(false)
                                        setModal('opening')
                                    }}>
                                        <IconClose />
                                    </span>
                                </MobileModalHeader>
                                <MobileModalBody>
                                    {modal === 'special' ? <>
                                        <SettingsFormGroupInput>
                                            <SettingsFormLabelContainer>
                                                <SettingsFormLabelLeftContainer>Date</SettingsFormLabelLeftContainer>
                                            </SettingsFormLabelContainer>
                                            <SettingsFormInputContainer>
                                                <IconCalendarAlt />
                                                <DatePicker
                                                    date={new Date()}
                                                    displayFormat='MMM D, Y'
                                                    locale='en-AU'
                                                    timezone='Australia/Brisbane'
                                                    onSelect={() => { }}
                                                />
                                            </SettingsFormInputContainer>
                                        </SettingsFormGroupInput>
                                        <SettingsFormGroupInput>
                                            <SettingsFormLabelContainer>
                                                <SettingsFormLabelLeftContainer>Operation</SettingsFormLabelLeftContainer>
                                                <SettingsFormLabelRightContainer><IconHelp /></SettingsFormLabelRightContainer>
                                            </SettingsFormLabelContainer>
                                            <SettingsFormInputContainer style={{ paddingLeft: 0, paddingRight: 0, border: 'none' }}>
                                                <SettingsCustomOptionsContainer style={{ flexDirection: 'row', width: '100%' }}>
                                                    <SettingsCustomOption className='active' style={{ flex: 1 }}>
                                                        Open
                                                        <IconCheck />
                                                    </SettingsCustomOption>
                                                    <SettingsCustomOption style={{ flex: 1 }}>Close</SettingsCustomOption>
                                                </SettingsCustomOptionsContainer>
                                            </SettingsFormInputContainer>
                                        </SettingsFormGroupInput></>
                                        : <SettingsFormGroupInput>
                                            <SettingsFormLabelContainer>
                                                <SettingsFormLabelLeftContainer>Day</SettingsFormLabelLeftContainer>
                                            </SettingsFormLabelContainer>
                                            <SettingsFormInputContainer>
                                                <select
                                                    value={openingHour.day}
                                                    onChange={(e) => {
                                                        setOpeningHour({
                                                            ...openingHour,
                                                            day: e.target.value
                                                        })
                                                    }}
                                                    className='bg-white'>
                                                    <option className='bg-white'>Monday</option>
                                                    <option className='bg-white'>Tuesday</option>
                                                    <option className='bg-white'>Wednesday</option>
                                                    <option className='bg-white'>Thursday</option>
                                                    <option className='bg-white'>Friday</option>
                                                    <option className='bg-white'>Saturday</option>
                                                    <option className='bg-white'>Sunday</option>
                                                </select>
                                            </SettingsFormInputContainer>
                                        </SettingsFormGroupInput>}
                                    <SettingsSwitchContainer>
                                        <SettingsFormLabelLeftContainer>
                                            Open 24 Hours
                                        </SettingsFormLabelLeftContainer>
                                        <Switch
                                            id="h24"
                                            checked={openingHour.h24}
                                            onChange={(e) => {
                                                setOpeningHour({
                                                    ...openingHour,
                                                    h24: e.target.checked
                                                })
                                            }}
                                            variant="black"
                                        />
                                    </SettingsSwitchContainer>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Open</SettingsFormLabelLeftContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer className={openingHour.h24 ? 'disabled' : ''}>
                                            <InputMask mask="99:99" value={openingHour.open} 
                                                onChange={(e) => {
                                                    setOpeningHour({
                                                        ...openingHour,
                                                        open: e.target.value
                                                    })
                                                }} disabled={openingHour.h24}>
                                                {(inputProps: any) => <Input type="text" className="no-round" style={{ width: 100 }} placeholder="00:00" {...inputProps} disabled={openingHour.h24} />}
                                            </InputMask>
                                            
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                    <SettingsFormGroupInput>
                                        <SettingsFormLabelContainer>
                                            <SettingsFormLabelLeftContainer>Close</SettingsFormLabelLeftContainer>
                                        </SettingsFormLabelContainer>
                                        <SettingsFormInputContainer className={openingHour.h24 ? 'disabled' : ''}>
                                             <InputMask mask="99:99" value={openingHour.close} 
                                                onChange={(e) => {
                                                    setOpeningHour({
                                                        ...openingHour,
                                                        close: e.target.value
                                                    })
                                                }} disabled={openingHour.h24}>
                                                {(inputProps: any) => <Input type="text" className="no-round" style={{ width: 100 }} placeholder="00:00" {...inputProps} disabled={openingHour.h24} />}
                                            </InputMask>
                                        </SettingsFormInputContainer>
                                    </SettingsFormGroupInput>
                                </MobileModalBody>
                                <MobileModalFooter>
                                    {edit ?
                                        <>
                                            <SettingsActionButtonWrapper position='relative' noborder nopadding>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        const copyOpeningHours = form.values.opening_hours
                                                        //@ts-ignore
                                                        copyOpeningHours[openingHourIndex] = openingHour
                                                        setShowModal(!showModal)
                                                        setEdit(false)
                                                        setModal('opening')
                                                        setFieldValue('opening_hours', copyOpeningHours)

                                                    }}
                                                > Update</button>
                                            </SettingsActionButtonWrapper>
                                            <SettingsActionButtonWrapper variant="white" position='relative' noborder nopadding>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const copyOpeningHour = form.values.opening_hours[openingHourIndex]
                                                        setShowModal(!showModal)
                                                        setEdit(false)
                                                        setModal('opening')
                                                        setFieldValue('opening_hours', form.values.opening_hours.concat(copyOpeningHour as T.Core.Business.BusinessHoursSlot))

                                                    }}
                                                > Duplicate</button>
                                            </SettingsActionButtonWrapper>
                                            <SettingsActionButtonWrapper variant="default" position='relative' noborder nopadding>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const copyOpeningHours = form.values.opening_hours
                                                        copyOpeningHours.splice(openingHourIndex, 1)
                                                        setShowModal(!showModal)
                                                        setEdit(false)
                                                        setModal('opening')
                                                        setFieldValue('opening_hours', copyOpeningHours)

                                                    }}> Delete</button>
                                            </SettingsActionButtonWrapper>
                                        </>
                                        : <SettingsActionButtonWrapper
                                            position='relative' noborder nopadding>
                                            <button type="button" onClick={() => {
                                                setShowModal(!showModal)
                                                setEdit(false)
                                                setModal('opening')
                                                setFieldValue('opening_hours', form.values.opening_hours.concat(openingHour as T.Core.Business.BusinessHoursSlot))
                                            }}> Save</button>
                                        </SettingsActionButtonWrapper>}
                                </MobileModalFooter>
                            </MobileModalContent>
                        </MobileModal>}

                    </div>
                    )
                }}
            </RestaurantForm>
            <SpecialHourModal show={!!modalType} modalType={modalType} selectedIndex={selectedIndex} selectedSpecialHour={selectedSpecialHour}
                setShow={() => {
                    setSpecialHourControl(prev => ({
                        ...prev,
                        selectedIndex: undefined,
                        modalType: ''
                    }));
                }}
            />
        </div>
    )
}
