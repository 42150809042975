import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../../mobx/components';
import { FooterSection, ModalMobileTabs, Sidebar } from '@lib/components';
import { ModalContent, ModalTitle } from '@lib/components';
import { RestaurantForm } from '../../../../../mobx/components/restaurant-form';
import { FastField } from 'formik';
import { FormGroup } from '@lib/components';
import { Switch } from '@lib/components';
import { Button } from '@lib/components';
import { RotateLoader } from '@lib/components';
import * as React from 'react';
import { ServiceAutoStatusFields } from '../settings/forms/services/base';
import styled from 'styled-components';
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader, SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../settings-v2';
import { IconClose } from '../../../../components/v2/icons';
import { TabGroup } from '../settings-v2/receipt-printing';
import { TabItem } from '../settings-v2/receipt-printing';

export const SettingServiceWrapper = styled.div`
  height: calc(100vh - 83px);
  overflow-y: scroll;
  padding-bottom: 166px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  position: relative;

  @media (min-width: 768px) {
    height: auto;
    overflow-y: unset;
    padding-bottom: 83px;
  }
  `;
export const SaveButtonSetting = styled.div<{isMobile?: boolean}>`
  position: fixed;
  bottom: 0;
  width: 467px;
  padding: 8px 16px;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #dfdfdf;
  @media(max-width: 767px){
    width: 100%;
  } 
`;
export const QuickSettingsWrapper = styled.div`
  color: #4B4F5E;
  font-family: Inter;
  font-style: normal;
  line-height: 24px; 
  position: relative;
  z-index: 200;
`
const SaveButtonWrapper = styled.div`
  width: 100%;
	.mobile-view {
    display: none;
  }
  @media (max-width: 767px) {
    .desktop-view {
      display: none;
    }
    .mobile-view {
      display: flex;
	  padding: none;
    }
  }
`;

interface Props {
  active: boolean;
  close: () => void;
  isMobile?: boolean;
}
interface State {
  tab: string;
  activeModal: boolean;
}

@inject('store')
@observer
export class RestaurantOrdersQuickSettingsV2 extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: '0',
      activeModal: false
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.active && !prevProps.active) {
      this.setState({ tab: '0' });
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.adjustButtonPosition);
    this.adjustButtonPosition();
    }

  componentWillUnmount() {
        window.removeEventListener('resize', this.adjustButtonPosition);
    }

    adjustButtonPosition() {
        const button = document.querySelector('.save-quick-settings-button');
        if (button) {
            const viewportHeight = window.innerHeight;
            const clientHeight = document.documentElement.clientHeight;
            const addressBarHeight = viewportHeight - clientHeight;
            (button as HTMLElement).style.bottom = `${addressBarHeight}px`;
        }
    }
  handleTabChange = (selectedTab: any) => {
    this.setState({ tab: selectedTab });
  };

  render() {
    const { active, close, isMobile } = this.props;
    const { tab } = this.state;
    const R = this.injected.store.restaurant!;
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari= /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    const paddingBottom = (isChrome || isSafari) ? '60px' : '0px';
    return (
       active && <MobileModal className='dashboard-filter-modal'>
            <MobileModalContent style={{height: '100%', justifyContent: 'stretch'}}>
              <MobileModalHeader>
                  Quick Service Settings
                  <span onClick={close}>
                      <IconClose />
                  </span>
              </MobileModalHeader>
              <MobileModalBody style={{paddingTop: 0}}>
                <TabGroup style={{paddingTop: 0, paddingLeft: 0, paddingRight: 0, overflow: 'unset'}}>
                  <TabItem
                      style={{flex: 1}}
                      onClick={() => this.handleTabChange('0')}
                      active={tab == '0'}>
                      Pickup
                  </TabItem>
                  <TabItem
                    style={{flex: 1}}
                      onClick={() => this.handleTabChange('1')}
                      active={tab == '1'}>
                      Delivery
                  </TabItem>
                  <TabItem
                      style={{flex: 1}}
                      onClick={() => this.handleTabChange('2')}
                      active={tab == '2'}>
                      Dine-In
                  </TabItem>
                </TabGroup>

                {tab === '0' && (
                  <RestaurantForm<T.Schema.Restaurant.RestaurantSchema['settings']['services']['pickup']>
                    submit={async (r, values) => {
                      r.settings.services.pickup = values;
                      const update = { $set: { 'settings.services.pickup': r.settings.services.pickup } };
                      return { r, update };
                    }}
                    initialValues={R.settings.services.pickup}
                    onSuccess={close}
                    onSuccessMessage={'Settings updated'}
                    width='100%'
                  >
                    {({ form, error, getFieldError }) => {
                      const { isSubmitting, setFieldValue } = form;
                      return (<>
                        <SettingServiceWrapper>
                            <FastField
                                name="enabled"
                                render={({ field }: any) => (
                                  <FormGroup
                                    title="Pickup Service"
                                    title_font_size={'16px'}
                                  >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center', marginTop: '16px'}}>
                                      <p>Allow customers to place orders that can be collected at your store location</p>
                                      <Switch
                                        id="enabled"
                                        checked={field.value}
                                        onChange={e => setFieldValue('enabled', e.target.checked)}
                                      />
                                    </div>
                                  </FormGroup>
                                )}
                              />

                            <p className="big font-semi-bold" style={{marginBottom: 16}}>Wait Times & Auto Status</p>
                            <ServiceAutoStatusFields service="pickup" values={form.values} />
                            
                        </SettingServiceWrapper>
                        <SettingsActionButtonWrapper className='mx-420'>
                          <button type="submit" disabled={isSubmitting}> 
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                          </button>
                        </SettingsActionButtonWrapper> 
                        </>
                        
                      );
                    }}
                  </RestaurantForm>
                )}

                {tab === '1' && (
                  <RestaurantForm<T.Schema.Restaurant.RestaurantSchema['settings']['services']['delivery']>
                    submit={async (r, values) => {
                      r.settings.services.delivery = values;
                      const update = { $set: { 'settings.services.delivery': r.settings.services.delivery } };
                      return { r, update };
                    }}
                    initialValues={R.settings.services.delivery}
                    onSuccess={close}
                    onSuccessMessage={'Settings updated'}
                  >
                    {({ form, error, getFieldError }) => {
                      const { isSubmitting, setFieldValue } = form;
                      return (<>
                        <SettingServiceWrapper>
                            <FastField
                                name="enabled"
                                render={({ field }: any) => (
                                  <FormGroup
                                    title="Delivery Service"
                                    title_font_size={'16px'}
                                  >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center'}}>
                                      <p>Allow customers to place orders that will be delivered to their address</p>
                                      <Switch
                                        id="delivery-enabled-switch"
                                        checked={field.value}
                                        onChange={e => setFieldValue('enabled', e.target.checked)}
                                    />
                                    </div>
                                  </FormGroup>
                                )}
                              />

                            <p className="big font-semi-bold" style={{marginBottom: 16}}>Wait Times & Auto Status</p>

                            <ServiceAutoStatusFields service="delivery" values={form.values} />
                        </SettingServiceWrapper>
                          

                        <SettingsActionButtonWrapper className='mx-420'>
                          <button type="submit" disabled={isSubmitting}> 
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                          </button>
                        </SettingsActionButtonWrapper> 
                        </>
                      );
                    }}
                  </RestaurantForm>
                )}

              {tab === '2' && (
                <RestaurantForm<T.Schema.Restaurant.RestaurantSchema['settings']['services']['dine_in']>
                  submit={async (r, values) => {
                    r.settings.services.dine_in = values;
                    const update = { $set: { 'settings.services.dine_in': r.settings.services.dine_in } };
                    return { r, update };
                  }}
                  initialValues={R.settings.services.dine_in}
                  onSuccess={close}
                  onSuccessMessage={'Settings updated'}
                >
                  {({ form, error, getFieldError }) => {
                    const { isSubmitting, setFieldValue } = form;
                    return (<>
                      <SettingServiceWrapper>
                            <FastField
                              name="enabled"
                              render={({ field }: any) => (
                                <FormGroup
                                  title="Dine-In service"
                                  title_font_size={'16px'}
                                >
                                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center'}}>
                                    <p>Allow customers to place orders while seated at a table</p>
                                    <Switch
                                      id="dine-in-pickup-switch"
                                      checked={field.value}
                                      onChange={e => setFieldValue('enabled', e.target.checked)}
                                    />
                                  </div>
                                  
                                </FormGroup>
                              )}
                            />

                          <p className="big font-semi-bold" style={{marginBottom: 16}}>Wait Times & Auto Status</p>
                          <ServiceAutoStatusFields service="dine_in" values={form.values} />
                        </SettingServiceWrapper>

                          <SettingsActionButtonWrapper className='mx-420'>
                          <button type="submit" disabled={isSubmitting}> 
                            {isSubmitting && <RotateLoader size={2} color="white" />}
                            {!isSubmitting && "Save"}
                          </button>
                          </SettingsActionButtonWrapper> 
                      </>
                    );
                  }}
                </RestaurantForm>
              )}
                
              </MobileModalBody>
            </MobileModalContent>
        </MobileModal> 
    );
  }
}
