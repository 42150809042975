import * as React from 'react';
import Big from 'big.js';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
import moment from 'moment-timezone';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { WithTranslation, withTranslation } from 'react-i18next';
import { action, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import {
  DetailRowListItem,
  ConfirmationModal,
  ModalContent,
  DetailRowList,
  LinkTag,
  ModalTabs,
  OrderItems,
  OrderNumber,
  OrderTimeline,
  OrderTotals,
  RelativeTime,
  Select,
  Tag,
} from '@lib/components';
import { OrderUtils, RestaurantUtils, SortType, logger, FORMATS, PaymentMethods, Untrusive } from '@lib/common';
import { getDeliveryOrderId, getDeliveryVia } from '../../../../../core/utils';
import { UI } from '../../../../../core/ui';

import { LalamoveScheduling } from './lalamove-scheduling';
import { MobxComponent } from '../../../../../mobx/components';

import { RefundModal } from './refund-modal';
import { RestaurantItemModal } from '../../../common/item-modal';
import { StripeConnectRefundModal } from './stripe/stripe-connect-refund-modal';
import { getOrderPaymentAvsStatus } from './helper';
import { RefundablePaymentMethods } from '@lib/common/src/data/payment_methods';
import { UberEstimation } from './uber-estimation';

const refundableOrderPaymentStatus = ['partial_refunded', 'success'];
const refundedOrderPaymentStatus = ['partial_refunded', 'refunded'];

import { RefundablePaymentMethodTypes } from '@lib/common'
import { isArray } from 'lodash';

interface State {
  lalamoveModalActive: boolean;
  uberModalActive: boolean;
  refundModal: 'stripe' | 'stripe_connect' | '' | RefundablePaymentMethodTypes;
  fetchStripeError: boolean;
  stripeData: any;
  confirmationModalVisible: boolean;
  orderTimeSelected: string;
}
interface Props extends WithTranslation { }

const NumberTag = styled.span`
  border-radius: 50%;
  background: #eaeaea;
  padding: 5px;
  min-width: 20px;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
`;
const ImgUber = styled.div`
  position: relative;
  & img {
    height: 28px;
    width: 74px;
    object-fit: cover;
    z-index: 1;
  }
`;

const orderLogTitles = {
  status_update: 'Update Status',
  due_update: 'Update Due Time',
  print_request: 'Print Request',
  tookan_book: 'Book Delivery Tookan',
  uber: 'Uber Update',
};

const addIndicatorsToItems = (items: any, taxRates: any): any => {
  const itemsTaxIndicatorCopy = items.map((item: any) => {
    taxRates.forEach((rate: any) => {
      if (rate.tax_indicator_flag && rate.dish_tax && rate.dish_tax.indexOf(item._id) === -1) {
        item.name = item.name + rate.tax_indicator_character;
      }
    });
    return item;
  });
  return itemsTaxIndicatorCopy;
};

@inject('store')
@observer
export class RestaurantOrderModalClass extends MobxComponent<Props, State> {
  last_id: string | null = null;
  currentOrder: any;
  @observable tab: number = 0;
  @action setTab = (tab: number) => (this.tab = tab);
  constructor(props: Props) {
    super(props);
    this.state = {
      lalamoveModalActive: false,
      uberModalActive: false,
      confirmationModalVisible: false,
      orderTimeSelected: '',
      refundModal: '',
      fetchStripeError: false,
      stripeData: {
        amount_refunded: 0,
        amount_captured: 0,
      },
    };
  }

  query = async () => {
    const { store } = this.injected;
    let result = await store.getOrder();

    if (result?.message) {
      UI.notification.error(result.message);
    }
  };

  componentDidUpdate() {
    this.getStripe();
  }

  async getStripe() {
    const { store } = this.injected;
    if (store.order && store.order != this.currentOrder) {
      const paymentIntentId = store.order.payment.stripe_connect_payment_intent_id || store.order.payment.stripe_paymentIntent_id
      if (paymentIntentId && store.reseller) {
        const stripeData = await store.getStripeTransaction(paymentIntentId, store.reseller._id);
        this.currentOrder = store.order;
        this.setState({ stripeData: stripeData });
      }
    }
  }
  close = () => {
    const { store } = this.injected;
    store.router.push(`${store.router.s.path}`);
    store.setOrder(null);
  };

  clearSelectInput = (id: string) => {
    const el = document.getElementById(id) as HTMLSelectElement | null;
    if (el) {
      el.value = '';
    }
  };

  canScheduleLalamoveOrder = (order: T.Schema.Order.OrderSchema) => {
    const lalamoveOrderStatus = order.config.lalamove_order_status || '';
    const canScheduleLalamoveOrder =
      order.config.lalamove_error || ['CANCELED', 'REJECTED', 'EXPIRED'].includes(lalamoveOrderStatus);
    return (
      order.config.service === 'delivery' && order.config.delivery_provider === 'lalamove' && canScheduleLalamoveOrder
    );
  };

  handleStatusSelect = async (o: T.Schema.Order.OrderSchema, status: T.Schema.Order.OrderStatuses) => {
    if (!status) return;
    this.clearSelectInput('order-status-select');
    await this.injected.store.service.order.update_status(o._id, status);
  };

  handleActionSelect = async (o: T.Schema.Order.OrderSchema, value: string) => {
    if (!value) {
      return;
    }

    this.clearSelectInput('order-action-select');

    const { store } = this.injected;

    if (value.indexOf('print-') !== -1) {
      try {
        Untrusive.start();
        const r = store.restaurant!;
        const type = 'order';
        const restaurant_id = r._id;
        const printer_id = value.split('print-')[1];
        const type_id = o._id;
        const job_id = nanoid();
        await store.api.print_receipt({
          type,
          restaurant_id,
          type_id,
          printer_id,
          job_id,
        });
        UI.notification.success('Order sent to printer');
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'refund-stripe') {
      try {
        const proceed = confirm('Once refunded, it cannot be reversed. Refund the order?');
        if (!proceed) {
          return;
        }
        Untrusive.start();
        const { _id } = o;
        const restaurant_id = store.restaurant!._id;
        const response = await store.api.order_stripe_refund({
          _id,
          restaurant_id,
        });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        } else {
          store.updateOrderComplete(response.order);
          UI.notification.success('Payment refunded');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'refund-stripe-connect') this.setState({ refundModal: 'stripe' });
    if (value === 'refund-swish-payment') this.setState({ refundModal: 'swish' });
    if (value === 'refund-fiserv-payment') this.setState({ refundModal: 'fiserv' });
    if (value === 'refund-bambora-na-payment') this.setState({ refundModal: 'bambora_na' });
    if (value === 'refund-paymongo-payment') this.setState({ refundModal: 'paymongo' });

    if (value === 'refund-cardconnect') {
      try {
        const proceed = confirm('Once refunded, it cannot be reversed. Refund the order?');
        if (!proceed) {
          return;
        }
        Untrusive.start();
        const { _id } = o;
        const restaurant_id = store.restaurant!._id;
        const response = await store.api.order_cardconnect_refund({
          _id,
          restaurant_id,
        });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        } else {
          store.updateOrderComplete(response.order);
          UI.notification.success('Payment refunded');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-checkout-payment') {
      try {
        const proceed = confirm('Do you want to verify the payment status from Checkout.com?');
        if (!proceed) return;
        Untrusive.start();
        const restaurant_id = store.restaurant!._id;
        const checkout_payment_id = o.payment.reference || '';
        const response = await store.api.order_verify_checkout_payment({
          restaurant_id,
          checkout_payment_id,
        });
        if (response.outcome || !response.verified) {
          UI.notification.error('Cannot verify Checkout.com payment status.', { timeout: 6000 });
        } else {
          UI.notification.success('Checkout.com payment verified.');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify Checkout.com payment. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-swish-payment') {
      try {
        const proceed = confirm('Do you want to verify the payment status from Swish?');
        if (!proceed) return;

        Untrusive.start();
        const orderId = o._id;
        const restaurantId = store.restaurant!._id;
        const response = await store.api.orderVerifySwishPayment({
          orderId,
          restaurantId,
        });
        if (response.outcome || !response.verified) {
          UI.notification.error('Cannot verify Swish payment status.', { timeout: 6000 });
        } else {
          UI.notification.success('Swish payment verified.');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify Swish payment. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-swish-refund') {
      try {
        const proceed = confirm('Do you want to verify the refund status from Swish?');
        if (!proceed) return;
        Untrusive.start();
        const orderId = o._id;
        const restaurantId = store.restaurant!._id;
        const response = await store.api.orderVerifySwishRefund({
          orderId,
          restaurantId,
        });
        if (response.outcome || !response.verified) {
          UI.notification.error('Cannot verify Swish refund status.', { timeout: 6000 });
        } else {
          UI.notification.success('Swish refund verified.');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify Swish refund. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-fiserv-order') {
      try {
        const proceed = confirm('Do you want to verify this order from Fiserv?');
        if (!proceed) return;
        Untrusive.start();
        const orderId = o._id;
        const restaurantId = store.restaurant!._id;
        const response = await store.api.orderVerifyFiservOrder({
          orderId,
          restaurantId,
        });
        if (response.outcome || !response.verified) {
          UI.notification.error("Cannot verify Fiserv's order status.", { timeout: 6000 });
        } else {
          UI.notification.success('Fiserv order verified.');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify Fiserv Order. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-paymongo-order') {
      try {
        const proceed = confirm('Do you want to verify this order from PayMongo?');
        if (!proceed) return;

        Untrusive.start();

        const orderId = o._id;
        const restaurantId = store.restaurant!._id;

        let response;

        const paramResquest = {
          orderId,
          restaurantId,
        };

        if (o.payment.status === 'pending') {
          response = await store.api.orderVerifyPayMongoPayment(paramResquest);
        }

        if (o.payment.status === 'refunding') {
          response = await store.api.orderVerifyPayMongoRefund(paramResquest)
        }

        if (!response || response.outcome || !response.verified) {
          UI.notification.error("Cannot verify PayMongo's order status.", { timeout: 6000 });
        } else {
          UI.notification.success('PayMongo order verified.');
        }

      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify PayMongo Order. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-viva-wallet-transaction') {
      try {
        const proceed = confirm('Do you want to verify this order from Viva Wallet?');
        if (!proceed) return;
        Untrusive.start();
        const orderId = o._id;
        const restaurantId = store.restaurant!._id;
        const response = await store.api.orderVerifyVivaWalletTransaction({
          orderId,
          restaurantId,
        });
        if (response.outcome || !response.verified) {
          UI.notification.error('Cannot verify Viva Wallet transaction.', { timeout: 6000 });
        } else {
          UI.notification.success('Viva Wallet transaction verified.');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify Viva Wallet transaction. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-payway-payment') {
      try {
        const proceed = confirm('Do you want to verify the payment status from PayWay?');
        if (!proceed) return;
        Untrusive.start();
        const orderId = o._id;
        const restaurantId = store.restaurant!._id;
        const response = await store.api.orderVerifyPaywayPayment({ restaurantId, orderId });
        if (response.outcome || !response.verified) {
          UI.notification.error('Cannot verify PayWay payment status.', { timeout: 6000 });
        } else {
          UI.notification.success('PayWay payment verified.');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify PayWay payment. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'verify-gkash-payment') {
      try {
        const proceed = confirm('Do you want to verify the payment status from Gkash?');
        if (!proceed) return;

        Untrusive.start();

        const restaurantId = store.restaurant!._id;
        const orderId = o._id;

        await store.api.orderVerifyGkashPayment({
          restaurantId,
          orderId,
        });

        UI.notification.success('Verify Successfully!');
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('Failed to verify Gkash payment. Please try again.', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'book-tookan') {
      try {
        Untrusive.start();
        const order_id = o._id;
        const response = await store.api.order_book_tookan({
          _id: order_id,
        });
        if (response.outcome) {
          UI.notification.error(response.message);
        } else {
          store.setOrder(response.order);
          UI.notification.success('Order booked for delivery');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'delete') {
      try {
        const proceed = confirm('Once deleted, it cannot be recovered. Delete the order?');
        if (!proceed) {
          return;
        }
        Untrusive.start();
        const { _id } = o;
        const restaurant_id = store.restaurant!._id;
        const response = await store.api.order_delete({
          _id,
          restaurant_id,
        });
        if (response.outcome) {
          UI.notification.error(response.message, { timeout: 6000 });
        } else {
          this.close();
          store.removeOrder(_id);
          UI.notification.success('Order deleted');
        }
      } catch (e) {
        logger.captureException(e);
        UI.notification.error('An error occurred, try again soon or contact us', { timeout: 6000 });
      } finally {
        Untrusive.stop();
      }
    }

    if (value === 'schedule-lalamove-delivery') {
      this.setState({ lalamoveModalActive: true });
    }

    if (value === 'request-uber-delivery') {
      this.setState({
        uberModalActive: true,
      })
    }
  };

  handleReadyTimeSelect = async (o: T.Schema.Order.OrderSchema, value: string) => {
    if (!value) {
      return;
    }
    this.clearSelectInput('order-ready-time-select');
    this.setState({ orderTimeSelected: value });
    if (false) { // TODO: check if account is paid
      await this.injected.store.service.order.update_ready_time(o._id, parseInt(this.state.orderTimeSelected, 10), false);
    }
    else {
      this.setState({ confirmationModalVisible: true });
    }
  };

  handleConfirmationModal = async (o: T.Schema.Order.OrderSchema, emailNotif: boolean) => {
    this.setState({ confirmationModalVisible: false });
    await this.injected.store.service.order.update_ready_time(o._id, parseInt(this.state.orderTimeSelected, 10), emailNotif);
  }


  renderHeader = (o: T.Schema.Order.OrderSchema) => {
    const { t } = this.injected;

    /* IS GOOGLE MAPS NEEDED BELOW BECAUSE WITHOUT IT DELIVERY AND READY TIME ARE THE EXACT SAME */
    return (
      <ModalContent paddinglr={20} paddingtb={25} className="flex-l-r-center no-border">
        <OrderNumber>#{o.number}</OrderNumber>

        {o.config.service !== 'delivery' && o.ready_in && (
          <div className="text-right">
            <p>
              Est. Ready Time -{' '}
              <span className="font-semi-bold">
                {t('datetimeFromTimestamp', {
                  value: o.ready_in.timestamp,
                })}
              </span>
            </p>
            <p className="smaller m-t-1">
              Last updated <RelativeTime timestamp={o.updated || o.created} />
            </p>
          </div>
        )}

        {o.config.service === 'delivery' && !!(o.ready_in || o.delivery_in) && (
          <div className="text-right">
            {o.ready_in && (
              <p>
                Est. Driver Pickup time -{' '}
                <span className="font-semi-bold">
                  {t('datetimeFromTimestamp', {
                    value: o.config.delivery_provider === 'uber' && o.config.uber_order_pickup_eta ?
                      new Date(o.config.uber_order_pickup_eta).getTime() :
                      o.ready_in.timestamp,
                  })}
                </span>
              </p>
            )}
            {o.delivery_in && (
              <p className="m-t-1">
                Est. Delivery Time -{' '}
                <span className="font-semi-bold">
                  {t('datetimeFromTimestamp', {
                    value: o.config.delivery_provider === 'uber' && o.config.uber_order_eta ?
                      new Date(o.config.uber_order_eta).getTime() :
                      o.delivery_in.timestamp,
                  })}
                </span>
              </p>
            )}
            <p className="smaller m-t-1">
              Last updated <RelativeTime timestamp={o.updated || o.created} />
            </p>
          </div>
        )}
      </ModalContent>
    );
  };

  renderStatusSelect = (o: T.Schema.Order.OrderSchema, useAwaitingPayment: boolean) => {
    let options: Array<{
      label: string;
      value: T.Schema.Order.OrderStatuses;
      disabled: boolean;
    }> = [
        { label: 'Cancelled', value: 'cancelled', disabled: false },
        { label: 'Un-Confirmed', value: 'unconfirmed', disabled: false },
        {
          label: 'Awaiting Payment',
          value: 'awaiting_payment',
          disabled: false,
        },
        { label: 'Confirmed', value: 'confirmed', disabled: false },
        { label: 'Ready', value: 'ready', disabled: false },
        { label: 'On Route', value: 'on_route', disabled: false },
        { label: 'Complete', value: 'complete', disabled: false },
      ];

    for (const option of options) {
      if (option.value === o.status) {
        option.label = `${option.label} (Current)`;
        option.disabled = true;
      }
    }

    if (o.config.service !== 'delivery') {
      options = options.filter(option => option.value !== 'on_route');
    }

    if (o.config.service === 'delivery') {
      if (o.config.delivery_provider === 'lalamove') {
        options = options.map(option => {
          if (option.value === 'on_route' || option.value === 'complete') {
            option.disabled = true;
          }
          return option;
        });
      }
    }

    if (!useAwaitingPayment) options = options.filter(option => option.value !== 'awaiting_payment');

    return (
      <Select
        id="order-status-select"
        className="no-round"
        placeholder="Change Status"
        options={options}
        onChange={e => this.handleStatusSelect(o, e.target.value as T.Schema.Order.OrderStatuses)}
      />
    );
  };

  renderReadyTimeSelect = (o: T.Schema.Order.OrderSchema) => {
    const isDelivery = o.config.service === 'delivery';

    const options = [];

    for (let i = 1; i < 12; i++) {
      options.push({
        label: `Add ${i * 5} minutes`,
        value: (i * 5).toString(),
      });
    }

    for (let i = 6; i < 13; i++) {
      options.push({
        label: `Add ${i * 10} minutes`,
        value: (i * 10).toString(),
      });
    }

    const initialWord = !o.ready_in || !o.ready_in.timestamp ? 'Set' : 'Modify';

    return (
      <>
        <Select
          id="order-ready-time-select"
          className="no-round no-border-top"
          placeholder={isDelivery ? `${initialWord} Est. Driver Pickup Time` : `${initialWord} Est. Ready Time`}
          options={options}
          onChange={e => this.handleReadyTimeSelect(o, e.target.value)}
        />
        <ConfirmationModal
          id={`email-notif-confirmation-modal`}
          visible={this.state.confirmationModalVisible}
          header="Email Notification"
          description="You currently have a paid account."
          question={"Would you like to receive an email notification for the change in estimated time?"}
          cancelButtonText="Don't Send Email"
          confirmButtonText="Send Email"
          confirmButtonType="primary"
          cancel={() => this.handleConfirmationModal(o, false)}
          handleConfirm={() => this.handleConfirmationModal(o, true)}
        />
      </>
    );
  };

  renderActionsSelect = (o: T.Schema.Order.OrderSchema) => {
    const { restrictions } = this.injected.store;
    const r = this.injected.store.restaurant!;

    const options = [];

    if (r.settings.printers) {
      for (const p of r.settings.printers) {
        if (!p.disabled) {
          options.push({
            label: `Print - ${p.name}`,
            value: `print-${p._id}`,
          });
        }
      }
    }


    if (
      o.payment.method === 'stripe' &&
      (o.payment.stripe_paymentIntent_id || o.payment.reference) &&
      o.payment.status !== 'refunded' &&
      !o.payment.stripe_connect
    ) {
      options.push({
        label: `Refund Stripe Payment`,
        value: `refund-stripe`,
      });
    }

    let canPerformStripeConnectRefund;
    if (o.payment.stripe_connect_refunded_status === 'partial_refund') {
      canPerformStripeConnectRefund =
        (o.payment.method === 'stripe' || o.payment.method === 'stripe_digital_wallet') &&
        (o.payment.stripe_paymentIntent_id || o.payment.reference) &&
        o.payment.stripe_connect;
    } else {
      canPerformStripeConnectRefund =
        (o.payment.method === 'stripe' || o.payment.method === 'stripe_digital_wallet') &&
        (o.payment.stripe_paymentIntent_id || o.payment.reference) &&
        o.payment.status !== 'refunded' &&
        o.payment.stripe_connect;
    }

    const shouldEnableStripeConnectRefund = r.settings.payments.stripe &&
      r.settings.payments.stripe.enabled &&
      r.settings.payments.stripe.connect_account_id;

    if (canPerformStripeConnectRefund && shouldEnableStripeConnectRefund) {
      options.push({
        label: `Refund`,
        value: `refund-stripe-connect`,
      });
    }

    if (o.payment.method === 'cardconnect' && o.payment.reference && o.payment.status !== 'refunded') {
      options.push({
        label: `Refund CardConnect Payment`,
        value: `refund-cardconnect`,
      });
    }

    if (o.payment.method === 'checkout' && o.payment.reference && o.payment.status !== 'success') {
      options.push({
        label: 'Verify Checkout.com Payment',
        value: 'verify-checkout-payment',
      });
    }

    if (o.payment.method === 'swish' && o.payment.reference) {
      if (o.payment.status === 'pending')
        options.push({
          label: 'Verify Swish Payment',
          value: 'verify-swish-payment',
        });

      if (refundableOrderPaymentStatus.includes(o.payment.status))
        options.push({
          label: 'Refund Swish Payment',
          value: 'refund-swish-payment',
        });

      if (o.payment.refund_reference && o.payment.status === 'refunding')
        options.push({
          label: 'Verify Swish Refund',
          value: 'verify-swish-refund',
        });
    }

    if (o.payment.method === 'fiserv') {
      if (o.payment.status === 'pending' || o.payment.status === 'refunding')
        options.push({
          label: 'Verify Fiserv Order',
          value: 'verify-fiserv-order',
        });

      if (refundableOrderPaymentStatus.includes(o.payment.status))
        options.push({
          label: 'Refund Fiserv Payment',
          value: 'refund-fiserv-payment',
        });
    }

    if (o.payment.method === 'viva_wallet' && o.payment.status === 'pending') {
      options.push({
        label: 'Verify Viva Wallet Transaction',
        value: 'verify-viva-wallet-transaction',
      });
    }

    if (o.payment.method === 'payway' && o.payment.reference && o.payment.status !== 'success') {
      options.push({
        label: 'Verify PayWay Payment',
        value: 'verify-payway-payment',
      });
    }

    if (o.payment.method === 'gkash' && o.payment.status === 'pending') {
      options.push({
        label: 'Verify Gkash Payment',
        value: 'verify-gkash-payment',
      });
    }

    if (
      o.payment.method === 'bambora_na' &&
      o.payment.reference &&
      refundableOrderPaymentStatus.includes(o.payment.status)
    ) {
      options.push({
        label: 'Refund Worldline Payment',
        value: 'refund-bambora-na-payment',
      });
    }

    if (o.payment.method === 'paymongo') {
      if (o.payment.status === 'pending' || o.payment.status === 'refunding') {
        options.push({
          label: 'Verify PayMongo Order',
          value: 'verify-paymongo-order',
        });
      }

      if (refundableOrderPaymentStatus.includes(o.payment.status)) {
        options.push({
          label: 'Refund PayMongo Payment',
          value: 'refund-paymongo-payment',
        });
      }
    }

    const tk = r.settings.services.delivery.providers.tookan;
    if (o.config.service === 'delivery' && tk && tk.api_key && tk.utc_offset && !o.config.tookan_job_id) {
      options.push({
        label: 'Book for delivery (Tookan)',
        value: 'book-tookan',
      });
    }

    if (restrictions.restaurant.orders_delete) {
      options.push({
        label: 'Delete Order',
        value: 'delete',
      });
    }

    if (this.canScheduleLalamoveOrder(o)) {
      options.push({
        label: 'Schedule Lalamove Delivery',
        value: 'schedule-lalamove-delivery',
      });
    }

    if (o.config.service === 'delivery' &&
      o.config.delivery_provider == 'uber' &&
      (o.config.uber_error || o.config.uber_delivery_error ||
        ['canceled', 'returned'].includes(o.config.uber_order_status!))) {
      options.push({
        label: 'Request Uber Delivery',
        value: 'request-uber-delivery'
      })
    }

    if (options.length === 0) {
      return null;
    }

    return (
      <Select
        id="order-action-select"
        className="no-round no-border-top"
        placeholder="Actions"
        options={options}
        onChange={e => this.handleActionSelect(o, e.target.value)}
      />
    );
  };

  closeRefundModal = () => this.setState({ refundModal: '' });

  render() {
    const { tab } = this;
    const { store, t } = this.injected;
    const { restrictions } = store;
    const { query } = store.router.s;
    const queryId = query._id || query.order_id || null;
    const item = store.order;
    const r = store.restaurant!;

    const computePaymentStatusValue = (orderPayment: T.Schema.Order.OrderCreateSchema['payment']) => {
      const paymentStatus = `constants.payment.status.${orderPayment.status}`;

      // keep the old code from Stripe Refund
      if (orderPayment.status === `refunded` && orderPayment.stripe_connect_refunded_status === 'partial_refund') {
        const amountRefunded = this.state.stripeData?.amount_refunded / 100 || 0;
        return `Refunded (${t('currency', { value: amountRefunded })})`;
      }

      if (
        RefundablePaymentMethods.includes(orderPayment.method) &&
        refundedOrderPaymentStatus.includes(orderPayment.status)
      )
        return `${t(paymentStatus)} (${t('currency', { value: orderPayment.refunded_amount })})`;

      return t(paymentStatus);
    };

    let items: any = [];
    if (item) {
      items = toJS(item.dishes);
      if (r && r.settings && r.settings.region && r.settings.region.tax && r.settings.region.tax.rates) {
        items = addIndicatorsToItems(items, r.settings.region.tax.rates);
      }
    }

    if (this.last_id !== queryId) {
      this.setTab(0);
    }
    this.last_id = queryId;

    const hidePrices = restrictions.misc && restrictions.misc.hide_prices;

    const uom = r.settings.region.kmmile === 'KM' ? "km's" : 'miles';
    const conversionNumber = r.settings.region.kmmile === 'KM' ? 1000 : 1609;

    const { refundModal, uberModalActive } = this.state;

    const useAwaitingPayment = !!r.settings.business.using_awaiting_payment;

    return (
      <div>
        <RestaurantItemModal<T.Schema.Order.OrderSchema>
          active={!!queryId}
          item={item}
          query={this.query}
          close={this.close}
        >
          {o => {
            // @ts-ignore
            const log = o.log ? o.log.filter(item => item.data?.from !== 'draft') : [];
            const { valid: avsValid, message: avsMessage, visible: avsVisible } = getOrderPaymentAvsStatus(o, r);
            let orderDetailFields = [
              {
                l: 'Type',
                v: t(`constants.services.${o.config.service}`),
              },
              {
                l: 'Origin',
                h: !o.config.source,
                v: (() => {
                  if (o.config.source && o.config.source.values.origin === "ubereats") {
                    return <>UberEats</>;
                  } else if (o.config.source && o.config.source.values.origin === "doordash") {
                    return <>DoorDash</>;
                  } else if (o.config.source && o.config.source.values.origin === "grubhub") {
                    return <>GrubHub</>;
                  }
                })()
              },
              {
                l: 'Table',
                h: o.config.service !== 'dine_in',
                v: o.config.table,
              },
              {
                l: 'Number Of People',
                h: o.config.service !== 'dine_in' || o.config.due !== 'later',
                v: o.config.number_of_people,
              },
              {
                l: 'Payment Method',
                v: o.payment.method_display_name ? o.payment.method_display_name : store.getPaymentMethodName(o.payment.method),
              },
              {
                l: 'Payment Status',
                h:
                  o.payment.method === 'cash' ||
                  o.payment.method === 'card' ||
                  PaymentMethods.indexOf(o.payment.method) === -1,
                v: (
                  <span
                    className={
                      o.payment.status === 'success'
                        ? 'success-text'
                        : ['error', 'refunded', 'partial_refunded', 'refunding'].indexOf(o.payment.status) !== -1
                          ? 'error-text'
                          : ''
                    }
                  >
                    <span className="block">{computePaymentStatusValue(o.payment)}</span>
                  </span>
                ),
              },
              {
                l: 'Payment Reference',
                h: o.payment.method === 'cash' || o.payment.method === 'card' || (!o.payment.reference && !o.payment.stripe_paymentIntent_id),
                v: o.payment.reference || o.payment.stripe_paymentIntent_id,
              },
              {
                l: 'Payment Refund Reference',
                h: o.payment.method === 'cash' || o.payment.method === 'card' || !o.payment.refund_reference,
                v: o.payment.refund_reference,
              },
              {
                l: 'Payment Currency',
                h: o.payment.currency === o.bill.currency,
                v: o.payment.currency,
              },
              {
                l: 'Payment AVS',
                h: !avsVisible,
                v: (() => (
                  <p className="small1 lhp">
                    <span className={avsValid ? '' : 'error-text'}>{avsMessage}</span>
                  </p>
                ))(),
              },
              {
                l: 'Placed',
                v: t('datetimeFromTimestamp', {
                  value: o.created,
                }),
              },
              {
                l: 'Due',
                v: (() => {
                  if (o.config.due === 'now') {
                    return 'Now / ASAP';
                  }
                  const { date, time } = o.config;
                  const timestamp = moment.tz(`${date} ${time}`, FORMATS.moment.datetime, store.intl.s.tz).valueOf();
                  return t('datetimeFromTimestamp', {
                    value: timestamp,
                  });
                })(),
              },
              {
                l: 'Received by Customer',
                v: o.config.is_received_by_customer ? 'Yes' : 'No',
                h: o.config.service !== 'dine_in'
              },
              {
                l: 'Notes',
                v: o.notes,
              },
              {
                l: 'Delivery Zone',
                h: o.config.service !== 'delivery' || !o.config.zone,
                v: o.config.zone,
              },
              {
                l: 'Delivery Address',
                h: o.config.service !== 'delivery',
                v: (() => {
                  let directions = null;
                  const md = r.location.map_data;
                  const oc = o.config;
                  if (_isUndefined(oc.lat) && _isUndefined(oc.lng)) {
                    return null
                  }
                  if (md.type !== 'custom') {
                    const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${md.lat},${md.lng}&destination=${oc.lat},${oc.lng}`;
                    directions = (
                      <span>
                        {' '}
                        -{' '}
                        <LinkTag href={directionsUrl} target="_blank">
                          Directions
                        </LinkTag>
                      </span>
                    );
                  }
                  return (
                    <span>
                      {(o.config.destination_misc ? `${o.config.destination_misc} - ` : '') + o.config.destination}{' '}
                      {directions}
                    </span>
                  );
                })(),
              },
              {
                l: 'Delivery Provider',
                h: !o.config.delivery_provider,
                v: RestaurantUtils.settings.getDeliveryProviderName(o.config.delivery_provider || ''),
              },
              {
                l: 'Delivery Order ID',
                h: !(o.config.delivery_provider && getDeliveryOrderId(o)),
                v: (() => <strong>{getDeliveryOrderId(o)}</strong>)(),
              },
              {
                l: 'Delivery Status',
                h: o.config.delivery_provider !== 'uber',
                v: (() => o.config.uber_order_status ? <span>{o.config.uber_order_status}</span> : null)()
              },
              {
                l: 'Delivery Time',
                h: o.config.delivery_provider !== 'uber',
                v: (() => o.config.uber_order_eta ? t('datetimeFromTimestamp', { value: o.config.uber_order_eta }) : null)()
              },
              {
                l: 'Delivery Tracking Url',
                h: !o.config.delivery_provider,
                v: (() => {
                  if (OrderUtils.hasDeliveryError(o)) {
                    return <span className="error-text">Failed to book delivery</span>;
                  }

                  const trackingUrl = OrderUtils.getTrackingUrl(o);

                  if (trackingUrl === '') {
                    return null;
                  }

                  const returnTrackingUrl = OrderUtils.getReturnTrackingUrl(o);
                  const deliveryStatus = OrderUtils.getDeliveryStatusFromProvider(o);

                  if (o.status !== 'cancelled') {
                    return (
                      <>
                        {trackingUrl && (
                          <div>
                            Booked for delivery (Track{' '}
                            <LinkTag target="_blank" href={trackingUrl}>
                              Delivery
                            </LinkTag>
                            )
                          </div>
                        )}
                        {deliveryStatus && (
                          <div>
                            <strong>{deliveryStatus}</strong>
                          </div>
                        )}
                      </>
                    );
                  }
                  if (returnTrackingUrl && o.status === 'cancelled') {
                    return (
                      <span>
                        Cancelled delivery (Track{' '}
                        <LinkTag target="_blank" href={trackingUrl}>
                          Return Delivery
                        </LinkTag>
                        )
                      </span>
                    );
                  }

                  return null;
                })(),
              },
              {
                l: 'Delivery Via',
                h: !o.config.delivery_provider,
                v: getDeliveryVia(o),
              },
              {
                l: 'Distance',
                h: o.config.service !== 'delivery' || !o.config.distance,
                v: o.config.distance ? `${Big(o.config.distance / conversionNumber).toFixed(3)} ${uom}` : null,
              },
              {
                l: 'Driving Time',
                h: o.config.service !== 'delivery' || !o.config.driving_time,
                v: o.config.driving_time ? `${Big(o.config.driving_time / 60).toFixed(2)} min` : null,
              },
              {
                l: 'Name',
                v: o.customer.name,
              },
              {
                l: 'E-Mail',
                v: o.customer.email,
              },
              {
                l: 'Phone',
                v: o.customer.phone,
              },
            ] as DetailRowListItem[];

            let checkoutFields = _get(o, 'checkout_fields');
            if (checkoutFields) {
              let checkoutOrderFields = checkoutFields.map((field: T.Schema.CustomFieldValue): DetailRowListItem => {
                let answer = field.answer;
                if (field.type === 'checkbox') {
                  answer = answer === 'yes' ? '✅' : '❌';
                }
                return {
                  l: field.question.label,
                  v: answer,
                };
              });

              const idx = _findIndex(orderDetailFields, (item: DetailRowListItem) => item.l === 'Notes');
              if (idx === -1) {
                orderDetailFields = [...orderDetailFields, ...checkoutOrderFields];
              } else {
                orderDetailFields.splice(idx, 0, ...checkoutOrderFields);
              }
            }

            return (
              <div>
                {this.renderHeader(o)}
                {this.renderStatusSelect(o, useAwaitingPayment)}
                {this.renderReadyTimeSelect(o)}
                {this.renderActionsSelect(o)}

                <OrderTimeline status={o.status} isDelivery={o.config.service === 'delivery'} />

                <ModalTabs
                  value={tab}
                  onChange={selected => this.setTab(selected.value as number)}
                  tabs={[
                    { label: 'Details', value: 0 },
                    { label: 'Items', value: 1 },
                    { label: 'Log', value: 2 },
                  ]}
                />

                {tab === 0 && (
                  <ModalContent paddinglr={20} paddingtb={25}>
                    <DetailRowList items={orderDetailFields} />
                  </ModalContent>
                )}

                {tab === 1 && (
                  <ModalContent paddinglr={20} paddingtb={15}>
                    <OrderItems
                      items={items}
                      renderDetails={{
                        enabled: true,
                        prices: !hidePrices,
                        option_prices: !hidePrices,
                        combo_choice_names: true,
                        disable_print_name: true,
                        sort_type: SortType.ByCart.toString(),
                      }}
                    />
                    {!hidePrices && (
                      <OrderTotals
                        tax_in_prices={o.bill.tax_in_prices}
                        fees={o.bill.fees}
                        taxes={o.bill.taxes}
                        discount={o.bill.discount}
                        promo={o.promos && isArray(o.promos) && o.promos.length > 0 ? o.promos[0] : null}
                        totalCart={o.bill.cart}
                        total={o.bill.total}
                        tip={typeof o.bill.tip === 'undefined' ? 0 : o.bill.tip}
                      />
                    )}
                    {r.settings.region.tax.rates.map((rate, index) => {
                      {
                        return rate.tax_indicator_flag ? (
                          <p
                            key={index}
                            className="small"
                            style={{
                              marginTop: '10px',
                            }}
                          >
                            {rate.tax_indicator_string}
                          </p>
                        ) : (
                          <div key={index}></div>
                        );
                      }
                    })}
                  </ModalContent>
                )}

                {tab === 2 && (
                  <ModalContent paddinglr={20} paddingtb={20}>
                    {!!(!log || log.length === 0) && <p className="text-center p-tb-8 big">No log entries...</p>}

                    {!!(log && log.length > 0) && (
                      <div className="">
                        <p className="m-b-3 p-b-3 border-white-b-15 small1">Sorted from newest to oldest</p>
                        {log.map(({ user, timestamp, data }, i) => {
                          return (
                            <div key={i} className={i === 0 ? '' : 'm-t-3 p-t-3 border-white-dash-t-15'}>
                              <div
                                style={{
                                  marginBottom: '7px',
                                }}
                                className="flex-line centered"
                              >
                                <NumberTag className="m-r-2">{log.length - i}</NumberTag>
                                <p
                                  className="font-semi-bold uppercase small"
                                  style={{
                                    color: '#838383',
                                  }}
                                >
                                  {orderLogTitles[data.type]}
                                </p>
                              </div>
                              <p className="lhp">
                                {data.type === 'status_update' &&
                                  `Updated from "${t(`order.status.${data.from}`)}" to "${t(
                                    `order.status.${data.to}`
                                  )}"`}
                                {data.type === 'due_update' &&
                                  o.config.service === 'delivery' &&
                                  `Delivery time updated to "${t(`datetimeFromTimestamp`, {
                                    value: data.due,
                                  })}"`}
                                {data.type === 'due_update' &&
                                  o.config.service !== 'delivery' &&
                                  `Ready time updated to "${t(`datetimeFromTimestamp`, {
                                    value: data.due,
                                  })}"`}
                                {data.type === 'print_request' && `Print request sent to printer "${data.printer}"`}
                                {data.type === 'tookan_book' &&
                                  (data.success
                                    ? 'Successfully booked delivery job with Tookan'
                                    : 'Failed to book delivery job with Tookan')}
                                {data.type === 'uber' && `${data.message}`}
                              </p>
                              <div className="m-t-2">
                                <Tag className="m-r-2">
                                  {t(`datetimeFromTimestamp`, {
                                    value: timestamp,
                                  })}
                                </Tag>
                                <Tag className="small">{user === 'system' ? 'System' : user}</Tag>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </ModalContent>
                )}

                {/* legacy refund modal code */}
                <StripeConnectRefundModal
                  order={o}
                  restaurant={r}
                  showModal={refundModal === 'stripe'}
                  onClose={this.closeRefundModal}
                />

                <RefundModal
                  paymentMethod={o.payment.method}
                  order={o}
                  restaurant={r}
                  showModal={RefundablePaymentMethods.includes(refundModal)}
                  onClose={this.closeRefundModal}
                />

                {this.canScheduleLalamoveOrder(o) && (
                  <LalamoveScheduling
                    order={o}
                    restaurant={r}
                    active={this.state.lalamoveModalActive}
                    close={() => this.setState({ lalamoveModalActive: false })}
                  />
                )}

                {uberModalActive && (
                  <UberEstimation
                    order={o}
                    restaurant={r}
                    active={uberModalActive}
                    onClose={() => this.setState({ uberModalActive: false })}
                  />

                )}


              </div>
            );
          }}
        </RestaurantItemModal>
      </div>
    );
  }
}

export const RestaurantOrderModal = withTranslation()(RestaurantOrderModalClass);
