import * as React from 'react';
import { FastField } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Checkbox, CommonText, FormGroup, SelectAdv, styled } from '@lib/components';
import { Button } from '@lib/components';
import { RotateLoader } from '@lib/components';
import { Input } from '@lib/components';
import { Switch } from '@lib/components';
import { Modal, ModalContent, ModalTitle } from '@lib/components';
import { UI } from '../../../../../../../core/ui';
import { MobxComponent } from '../../../../../../../mobx/components/index';
import { inject, observer } from 'mobx-react';
import { RestaurantForm } from '../../../../../../../mobx/components/restaurant-form';
import { config } from '../../../../../../../config';
import { AddressContainer, BusinessContainer, NameContainer, NameInput, TwoColumn } from '../system/billing-info';
import { DataCountries, RestaurantUtils } from '@lib/common';
import { SettingsActionButtonContainer, SettingsActionButtonWrapper } from '../../../settings-v2';

interface Props {
  close?: () => void;
}

type FormValues = T.Schema.Restaurant.RestaurantUberSettings | undefined;
type BillingInfo = T.Schema.BillingInfo;

type FormValuesUS = Partial<BillingInfo> & {
  enabled: boolean | undefined;
}



const ErrorBox = styled.div`
  color: red;
  padding: 4px 0;
`;

const InfoBox = styled.div`
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe;
    position: relative;
    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    margin-bottom: 1rem;
`

const TermsUL = styled.ul`
  margin-top: 15px;
  margin-bottom: 15px;
  list-style: none;
  padding-left: 0px;
  & li {
    margin-bottom: 10px;
  }
`
interface State {
  uberEnabled: boolean;
  termsModal: boolean;
  activeTab: 0 | 1;
  isLoadingZones: boolean;
  // serviceableZones: {
  //   postal: string,
  //   name: string,
  //   state: string
  // }[];
  serviceableZones: string[]
}

const Tabs = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 15px;
`;

const PostalCodesContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
`;

const StateContainer = styled.div`
  margin-bottom: 20px;
`

const CodesContainer = styled.div`
  width: 49%;
`

const CodeDetail = styled.div`
  border: 1px dashed #d6d6d6;
  padding: 10px;
  color: #757575;
`

@inject('store')
@observer
export class SettingsFormIntegrationUber extends MobxComponent<
  Props,
  State
> {
  initialValues: FormValues | FormValuesUS;
  webhookUrl: string;

  constructor(props: Props) {
    super(props);
    const r = this.injected.store.restaurant!;
    const countryCode = RestaurantUtils.settings.getCountryCodeFromLocation(this.injected.store.restaurant!);
    const uberData = r.settings.services.delivery.providers?.uber ?? {} as T.Schema.Restaurant.RestaurantUberSettings;
    this.initialValues = ["US", "CA"].includes(countryCode) ? { ...r.settings.billing_info, enabled: uberData?.enabled } : uberData;
    this.webhookUrl = `${config.urls.api2}/v1/uber/webhook`;
    this.state = {
      uberEnabled: !!this.initialValues?.enabled,
      termsModal: false,
      activeTab: 0,
      isLoadingZones: false,
      serviceableZones: []
    };
  }

  validateRequiredField(value: string, enabled: boolean) {
    let error;
    if (_isEmpty(value) && enabled) {
      error = 'This field value cannot be empty.';
    }
    return error;
  }

  validateRequiredCheckbox(value: any, enabled: boolean) {
    let error;
    if (value == false && enabled) {
      error = 'This field is required.';
    }
    return error;
  }

  getError(errors: any, key: string): string {
    if (errors && errors.uber) {
      const message = _get(errors.uber, key, '');
      return _isEmpty(message) ? '' : message;
    }
    return '';
  }

  // groupPostalCodeByState(): string[] {
  //   return [... new Set(this.state.serviceableZones.map(codes => codes.state))];
  // }

  async retrieveServiceableZones() {
    if (this.state.uberEnabled) {
      this.setState({ isLoadingZones: true })
      try {
        const res = await this.injected.store.api.get_serviceable_zones({
          restaurantId: this.injected.store.restaurant!._id
        })
        if (res.outcome === 1) {
          console.log(res.message);
          return;
        }
        // to change
        this.setState({ serviceableZones: res.serviceable.serviceable_dropoff_zip_codes })
      } catch (ex) {

      } finally {
        this.setState({ isLoadingZones: false })
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevState.uberEnabled !== this.state.uberEnabled
      || ((prevState.activeTab !== this.state.activeTab) && this.state.activeTab === 1)) {
      this.retrieveServiceableZones();
    }
  }

  render() {
    const countryCode = RestaurantUtils.settings.getCountryCodeFromLocation(this.injected.store.restaurant!);
    return (
      <>
        {
          this.state.uberEnabled && ["CA"].includes(countryCode) &&
          <Tabs>
            <Button
              full
              onClick={() => this.setState({ activeTab: 0 })}
              color={this.state.activeTab === 0 ? 'primary' : 'primary-inverse'}>
              Settings
            </Button>
            <Button
              full
              onClick={() => this.setState({ activeTab: 1 })}
              color={this.state.activeTab === 1 ? 'primary' : 'primary-inverse'}>
              Serviceable Postal Codes
            </Button>
          </Tabs>
        }
        {
          this.state.activeTab === 0 &&
          <RestaurantForm<FormValues | FormValuesUS>
            submit={async (r, values) => {
              if (["US", "CA"].includes(countryCode)) {
                const billingInfo = values as FormValuesUS;
                const newUberData = {
                  enabled: !!billingInfo?.enabled,
                  abn: billingInfo?.abn,
                  company_name: billingInfo?.business_name,
                  trading_name: billingInfo?.business_name,
                  director_name: `${billingInfo?.first_name ?? ''} ${billingInfo?.middle_name ?? ''} ${billingInfo?.last_name ?? ''}`,
                  mobile_number: this.injected.store.restaurant?.location.phone,
                  agree: !!billingInfo?.agree,
                }
                r.settings.billing_info = values as BillingInfo;
                r.settings.services.delivery.providers.uber = newUberData as FormValues;
                const update = {
                  $set: {
                    'settings.billing_info':
                      billingInfo,
                    'settings.services.delivery.providers': {
                      uber: newUberData
                    },
                    enable_uber_organization: !!billingInfo.enabled,
                  },
                };
                this.setState({ uberEnabled: !!billingInfo?.enabled });
                return { r, update };
              } else {
                r.settings.services.delivery.providers.uber = values as FormValues;
                const update = {
                  $set: {
                    'settings.services.delivery.providers': r.settings.services.delivery.providers,
                  },
                };
                this.setState({ uberEnabled: !!values?.enabled })
                return { r, update };
              }

            }}
            initialValues={this.initialValues}
            // onSuccess={() => {
            //   if (this.props.close) {
            //     this.props.close();
            //   }
            // }}
            onError={() => UI.notification.error('An error occurred')}
            onSuccessMessage="Settings Updated"
          >
            {({ form, error }) => {
              const { isSubmitting, setFieldValue, errors } = form;
              return (
                <>
                  <AddressContainer>
                    <FastField
                      name="enabled"
                      render={({ field }: any) => (
                        <NameInput>
                          <CommonText size="medium" weight={600} color='#212121'>
                            Enabled
                          </CommonText>
                          <Switch
                            id="uber-enabled-switch"
                            checked={field.value || false}
                            onChange={e =>
                              setFieldValue('enabled', e.target.checked)
                            }
                          />
                        </NameInput>
                      )}
                    />
                    <NameInput>
                      <CommonText size="semiMedium" weight={400} color='#212121'>
                        Enabling this will automatically trigger the Uber delivery process for new orders.
                      </CommonText>
                      <InfoBox>
                        <strong className="font-bold">Note</strong>: Delivery fees from third-party integrations are overriden by system-set values.
                        Go over to <strong className="font-bold">Settings &gt; Services &gt; Deliveries &gt; Fees</strong> and set it to None.
                      </InfoBox>
                    </NameInput>

                  </AddressContainer>

                  {["US", "CA"].includes(countryCode) ? <>
                    <FormGroup title="Business" no_border />
                    <BusinessContainer>
                      <NameInput className="name-input-100">
                        <FastField
                          name="business_name"
                          render={({ field }: any) => (
                            <Input type="text" required  {...field} placeholder="Business Name" />)} />
                      </NameInput>

                    </BusinessContainer>
                    <FastField
                      name="pickup_phone_number"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Pickup Phone Number"
                          help="Uber requires valid phone number from your store. This is autofilled up with your store phone number"
                        >
                          <>
                            <Input
                              // optional={true}
                              placeholder="Pickup Phone Number"
                              readOnly
                              type="text" {...field} value={this.injected.store.restaurant?.location.phone}
                            />

                          </>
                        </FormGroup>

                      )}
                    />
                    <FormGroup title="Director Details" no_border />
                    <NameContainer>
                      <NameInput>
                        <FastField
                          name="first_name"
                          render={({ field }: any) => (
                            <Input type="text" required  {...field} placeholder="First Name" />)} />
                      </NameInput>
                      <NameInput>
                        <FastField
                          name="middle_name"
                          render={({ field }: any) => (
                            <Input type="text" placeholder="Middle Name"   {...field} />)} />
                      </NameInput>
                      <NameInput>
                        <FastField
                          name="last_name"
                          render={({ field }: any) => (
                            <Input type="text" placeholder="Last Name" required  {...field} />)} />
                      </NameInput>
                    </NameContainer>
                    <NameContainer>
                      <NameInput className="name-input-100">

                        <FastField
                          name="phone_number"
                          render={({ field }: any) => (
                            <Input type="text" placeholder="Phone Number" required  {...field} />)} />
                      </NameInput>

                      <NameInput className="name-input-100">
                        <FastField
                          name="email"
                          render={({ field }: any) => (
                            <Input type="email" placeholder="Email" required  {...field} />)} />
                      </NameInput>
                    </NameContainer>
                    <FastField
                      name="abn"
                      validate={(val: any) => this.validateRequiredField(val, form.values?.enabled!)}
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="ABN/Business Registration Number/VAT/ Tax ID" type="text" {...field} value={field.value || ''} />
                          {this.getError(errors, 'abn') && (
                            <ErrorBox>{this.getError(errors, 'abn')}</ErrorBox>
                          )}
                        </FormGroup>
                      )}
                    />
                    <FormGroup title="Address" no_border />
                    <AddressContainer>
                      <NameInput className="name-input-100">
                        <FastField
                          name="street1"
                          render={({ field }: any) => (
                            <Input type="text" placeholder="Street 1" required  {...field} />)} />
                      </NameInput>
                      <NameInput className="name-input-100">
                        <FastField
                          name="street2"
                          render={({ field }: any) => (
                            <Input type="text" placeholder="Street 2"   {...field} />)} />
                      </NameInput>
                      <TwoColumn>
                        <NameInput className="name-input-100">
                          <FastField
                            name="city"
                            render={({ field }: any) => (
                              <Input type="text" placeholder="City" required  {...field} />)} />
                        </NameInput>
                        <NameInput className="name-input-100">
                          <FastField
                            name="zip_code"
                            render={({ field }: any) => (
                              <Input type="text" placeholder="Zip Code" required  {...field} />)} />
                        </NameInput>
                      </TwoColumn>
                      <TwoColumn>
                        <NameInput className="name-input-100">
                          <FastField
                            name="state"
                            render={({ field }: any) => (
                              <Input type="text" placeholder="State" required  {...field} />)} />
                        </NameInput>
                        <NameInput className="name-input-100">
                          <FastField
                            name="country"
                            render={({ field }: any) => (
                              <SelectAdv
                                type="single"
                                value={field.value}
                                placeholder="Country"
                                onChange={(v: string) => setFieldValue("country", v)}
                                options={DataCountries.map(({ label }) => ({ label, value: label }))} />

                            )} />
                        </NameInput>
                      </TwoColumn>

                    </AddressContainer>

                    <FormGroup no_border help="">

                    </FormGroup>
                  </> : <>
                    <FastField
                      name="pickup_phone_number"
                      render={({ field }: any) => (
                        <FormGroup
                          title="Pickup Phone Number"
                          help="Uber requires valid phone number from your store. This is autofilled up with your store phone number"
                        >
                          <>
                            <Input
                              optional={true}
                              placeholder="Phone Number"
                              readOnly
                              type="text" {...field} value={this.injected.store.restaurant?.location.phone}
                            />

                          </>
                        </FormGroup>

                      )}
                    />
                    <FastField
                      name="abn"
                      validate={(val: any) => this.validateRequiredField(val, form.values?.enabled!)}
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="ABN/Business Registration Number/VAT/ Tax ID" type="text" {...field} value={field.value || ''} />
                          {this.getError(errors, 'abn') && (
                            <ErrorBox>{this.getError(errors, 'abn')}</ErrorBox>
                          )}
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="company_name"
                      validate={(val: any) => this.validateRequiredField(val, form.values?.enabled!)}
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="Company Name" type="text" {...field} value={field.value || ''} />
                          {this.getError(errors, 'company_name') && (
                            <ErrorBox>{this.getError(errors, 'company_name')}</ErrorBox>
                          )}
                        </FormGroup>
                      )}
                    />
                    <FastField
                      name="trading_name"
                      validate={(val: any) => this.validateRequiredField(val, form.values?.enabled!)}
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="Trading Name" type="text" {...field} value={field.value || ''} />
                          {this.getError(errors, 'trading_name') && (
                            <ErrorBox>{this.getError(errors, 'trading_name')}</ErrorBox>
                          )}
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="director_name"
                      validate={(val: any) => this.validateRequiredField(val, form.values?.enabled!)}
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="Director Name" type="text" {...field} value={field.value || ''} />
                          {this.getError(errors, 'director_name') && (
                            <ErrorBox>{this.getError(errors, 'director_name')}</ErrorBox>
                          )}
                        </FormGroup>
                      )}
                    />

                    <FastField
                      name="mobile_number"
                      validate={(val: any) => this.validateRequiredField(val, form.values?.enabled!)}
                      render={({ field }: any) => (
                        <FormGroup className="no-border m-0">
                          <Input placeholder="Mobile Number" type="text" {...field} value={field.value || ''} />
                          {this.getError(errors, 'mobile_number') && (
                            <ErrorBox>{this.getError(errors, 'mobile_number')}</ErrorBox>
                          )}
                        </FormGroup>
                      )}
                    />
                  </>}

                  <FastField
                    name="agree"
                    validate={(val: any) => this.validateRequiredCheckbox(val, form.values?.enabled!)}
                    render={({ field }: any) => (
                      <FormGroup className="no-border m-0">
                        <Checkbox
                          id="agree"
                          checked={field.value}
                          className="m-t-0 inline m-r-1"
                          onChange={(e) => {
                            setFieldValue("agree", e.target.checked);
                          }}
                          label={
                            <p className="inline">
                              {'By enabling this feature, you understand and agree to the '}
                              <a className="link" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({ termsModal: true });
                              }
                              }>Terms and Conditions.</a>
                            </p>
                          }
                        />

                        {this.getError(errors, 'agree') && (
                          <ErrorBox>{this.getError(errors, 'agree')}</ErrorBox>
                        )}
                      </FormGroup>
                    )}
                  />


                  {error && <FormGroup error={error} />}

                  <Button
                    full={true}
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && <RotateLoader size={2} color="white" />}
                    {!isSubmitting && 'Save'}
                  </Button>
                </>
              );
            }}
          </RestaurantForm>
        }
        {
          this.state.activeTab === 1 &&
          <PostalCodesContainer>
            {
              this.state.isLoadingZones ?
                <h5>Retrieving Servicable Zones from your area...</h5>
                : <>
                  {
                    this.state.serviceableZones.length === 0 &&
                    <InfoBox>
                      No Serviceable Zones found on your location.
                    </InfoBox>
                  }
                  {
                    this.state.serviceableZones.length > 0 &&
                    this.state.serviceableZones.map(zipCodes => (
                      <CodesContainer key={zipCodes}>
                        <CodeDetail>
                          <h4>{zipCodes} </h4>
                          {/* <div>{ code.name } </div> */}
                        </CodeDetail>
                      </CodesContainer>
                      // <StateContainer>
                      //   <div style={{ marginBottom: 20 }}>
                      //     <h4>{state}</h4>
                      //   </div>
                      //   <CodesContainer>
                      //     {
                      //       this.state.serviceableZones.filter((code) => code.state === state).map((code) => (
                      //         <CodeDetail>
                      //           <h4 style={{ marginBottom: 2 }}>{ code.postal } </h4>
                      //           <div>{ code.name } </div>
                      //         </CodeDetail>
                      //       ))
                      //     }
                      //   </CodesContainer>
                      // </StateContainer>
                    ))
                  }
                </>
            }
          </PostalCodesContainer>
        }
        <Modal width={600} active={!!this.state.termsModal} close={() => this.setState({ termsModal: false })}>
          <ModalTitle className="round-top">
            <h4>Terms and Conditions</h4>
          </ModalTitle>

          <ModalContent>
            <ol>
              <li>
                <strong className="font-bold">Definitions and Interpretation</strong>
                <TermsUL>
                  <li className="m-b-2">1.1 "Platform" - the online ordering system provided for hospitality businesses that integrate with Uber Direct.</li>
                  <li className="m-b-2">1.2 "Terms" - these terms and conditions that govern the use of the Platform.</li>
                  <li className="m-b-2">1.3 "User" or "You" - any person or entity that accesses or utilizes the Platform.</li>
                </TermsUL>
              </li>
              <li>
                <strong className="font-bold">Acceptance of Terms</strong>
                <p className="m-t-3 m-b-3">By accessing and using the Platform, the User acknowledges and agrees to be bound by these Terms. Users not accepting these Terms must not access or use the Uber Direct integration.</p>
              </li>
              <li>
                <strong className="font-bold">Third-Party Delivery Services</strong>
                <TermsUL>
                  <li className="m-b-2">3.1 The User acknowledges and agrees that all fees associated with the utilization of Uber Direct delivery services are the User's sole responsibility.</li>
                  <li className="m-b-2">3.2 The Company shall not be held liable for any issues, damages, or losses arising from the use of Uber Direct delivery services, including but not limited to matters involving drivers, food quality, or delivery times.</li>
                  <li className="m-b-2">3.3 The Company's role concerning Uber Direct delivery services is limited to facilitating the transmission of orders to Uber Direct and receiving the associated quotes and payments.</li>
                </TermsUL>
              </li>
              <li>
                <strong className="font-bold">Data Sharing Authorization</strong>
                <p className="m-t-3 m-b-3">The User represents and warrants that they possess the requisite authority to transmit data to Uber Direct under applicable laws and regulations.</p>
              </li>
              <li>
                <strong className="font-bold">Termination of Services</strong>
                <TermsUL>
                  <li className="m-b-2">5.1 The Company reserves the right to suspend, cancel, or terminate the provision of services under these Terms at its sole discretion and without prior notice.</li>
                  <li className="m-b-2">5.2 The User may terminate their use of the Delivery service integration by providing the Company with a written notice of termination no less than 30 days before the intended termination date.</li>
                  <li className="m-b-2">5.3 Before the termination of services, the User shall be responsible for settling all outstanding fees due to the Company.</li>
                </TermsUL>
              </li>
              <li>
                <strong className="font-bold">User Responsibilities</strong>
                <TermsUL>
                  <li className="m-b-2">6.1 The User shall be solely responsible for addressing and resolving disputes, refund requests, or other issues with their respective customers.</li>
                  <li className="m-b-2">6.2 The Company provides support services during regular business hours from Monday to Friday and disclaims any liability arising from using the third-party delivery service.</li>
                </TermsUL>
              </li>
              <li>
                <strong className="font-bold">Prohibited Activities</strong>
                <p className="m-t-3 m-b-3">The User is prohibited from using the Platform to facilitate the sale of illegal products, age-restricted items, or orders exceeding $500.</p>
              </li>
              <li>
                <strong className="font-bold">Fair Use of Support Services</strong>
                <p className="m-t-3 m-b-3">The Company reserves the right to impose additional charges for support services in cases where the User's utilization of such services is deemed excessive or unreasonable.</p>
              </li>
              <li>
                <strong className="font-bold">Intellectual Property Rights</strong>
                <p className="m-t-3 m-b-3">All intellectual property rights of the Platform are the Company's exclusive property. Intellectual property rights associated with Uber Direct remain the property of the respective third-party service provider.</p>
              </li>
              <li>
                <strong className="font-bold">Payment Terms</strong>
                <TermsUL>
                  <li className="m-b-2">10.1 The User shall be responsible for any additional charges not covered by the initial delivery quote, payable through the payment method on file or via invoice.</li>
                  <li className="m-b-2">10.2 Payment for invoices is due upon receipt.</li>
                  <li className="m-b-2">10.3 The Company reserves the right to modify pricing structures and service charges at its discretion.</li>
                </TermsUL>
              </li>
              <li>
                <strong className="font-bold">Compliance with Data Protection Laws</strong>
                <p className="m-t-3 m-b-3">The User shall comply with all applicable data protection laws and regulations in their jurisdiction and seek appropriate legal advice concerning the handling, storing, and processing of customer data.</p>
              </li>
              <li>
                <strong className="font-bold">Amendments to Terms</strong>
                <p className="m-t-3 m-b-3">The Company reserves the right to modify or amend these Terms at any time. The User is responsible for regularly reviewing these Terms for any updates or changes.</p>
              </li>
            </ol>
          </ModalContent>
        </Modal>
      </>
    );
  }
}
