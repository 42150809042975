import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { RestaurantOrdersList } from "./list";
import { RestaurantOrdersBoard } from "./board";
import { RestaurantOrderModal } from "./modal";
import { Button, CommonText, Tooltip, ExportDataSidebar, Sidebar, Modal, ModalContent, RotateLoader } from "@lib/components";
import { FaPlus, FaMinus, FaCog, FaQuestionCircle, FaSync, FaEye, FaHourglassHalf, FaPlay, FaDownload } from "react-icons/fa";
import { IoIosPause } from "react-icons/io";
import { runInAction } from "mobx";
import { RestaurantOrdersQuickSettings } from "./quick-settings";
import localStore from "store";
import { LalamoveOrderSearch } from './lalamove-order-search';
import { UI } from "../../../../../core/ui";
import { RestaurantUtils, cloneDeepSafe, exportColumns } from "@lib/common";
import { RestaurantOrdersBump, getAllOrders } from "./bump/bump";
import styled from "styled-components";
import { FilterIcon, ViewIcon } from "./bump-icon";
import { BumpViewSidebar } from "./bump/bump-view-sidebar";
import { FilterViewScreen } from "./filter-view-modal";
import { PauseServicesSidebar } from "./sidebars/pauseServicesSidebar";
import { CountdownClock } from "./countdownClock";
import { getPauseServices, getPauseTime } from "../../../../utils/pause_services";
import { RestaurantOrdersBoardV2 } from "../orders-v2/board-v2";
import { checkIsMobile } from "../orders-v2/helper";
import { RestaurantOrdersListV2 } from "../orders-v2/list-v2";
import { FilterListViewV2 } from "../orders-v2/sidebar/filter-list-view";
import { RestaurantOrdersQuickSettingsV2 } from "../orders-v2/quick-setting-mobile";
import { config } from "../../../../../config";
import { ExportReportColumnModal } from "./edit-report-column/export-report-column";
import { SettingsHeader, SettingsHeaderMenuContainer, SettingsHeaderTextContainer, SettingsHeaderTextTitle } from "../settings-v2";
import { FilterOrderIcon, PauseIcon, QuestionCircleIcon, ResumeIcon, SpecialHoursIcon, SwitchIcon } from "../orders-v2/board-v2-icon";
import { RestaurantSpecialHours } from "../special-hours";
import { IconSettings } from "../../../../components/v2/icons";
import { DisplaySettings } from "../orders-v2/sidebar/display_setting";
import { PauseServicesSidebarV2 } from "./sidebars/v2";
import { RestaurantSpecialHoursV2 } from "../special-hours/index-v2";


interface Props extends WithTranslation {
	currentLayout?: 0 | 1 | 2;
}
interface State {
	quick_settings_active: boolean;
	bump_view_screen_active: boolean;
	// pause_services_active: boolean;
	filter_active: boolean;
	filter_list_active: boolean;
	display_settings_active: boolean;
	toggleSpecialHoursModal: boolean;
	fetching_data_bump: boolean;
	countdownFinished: boolean;
	export_data: boolean;
	export_data_column: boolean;
	orderExport: {
		columns: { [key: string]: { name: string; active: boolean } };
	};
	activeCol: string[];
	tempColumns: { [key: string]: { name: string; active: boolean } };
	checkResumeServices: boolean;
	displaySettings: {
		showUnconfirmColumn: boolean;
		isCancelledChecked: boolean;
		layoutView: string;
		cardView: string;
	};
}

const TOOLBAR_HEADER_HEIGHT = "58px";

const OrderBumpWapper = styled.div`
	width: 100%;
	height: 100%;
`;
const RestaurantOrderWrapper = styled.div`
   width: 100%;
      .mobile-view {
        display: none;
      }
        .desktop-view {
            height: 100%;
			padding: 12px 0 0 24px;
			width: 100%;
          }
        @media (max-width: 767px) {
          .desktop-view {
            display: none;
          }
          .mobile-view {
            display: block;
          }
        }
`;
const OrderToolBarWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px 24px;
	background-color: #fff;
	gap: 8px;
	@media (max-width: 1000px) {
		justify-content: center;
		padding: 10px 8px;
		flex-wrap: wrap;
	}
`;
const ModeButtonWrapper = styled.div`
	@media (max-width: 1000px) {
		width: 100%;
	}
`;
const ControlButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	@media (max-width: 1000px) {
		width: 100%;
	}
`;


const LayoutViewWrapper = styled.div<{ customStyle: string }>`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: #F1F4F7;
	padding: ${({ customStyle }) => (customStyle === 'list' ? '2rem' : '0')};
	height: calc(100vh - 133px);
	margin-bottom: ${TOOLBAR_HEADER_HEIGHT};
	position: absolute;
	@media (max-width: 767px) {
      width: 100vw;
	  padding: ${({ customStyle }) => (customStyle === 'list' && '0')};
  }
`;
const ModeButtonStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F1F4F7;
	border-radius: 4px;
	min-width: 162px;
	button {
		width: 100%;
	}
	@media (max-width: 1000px) {
		min-width: 90px;
		flex: 1;
	}
`;
const ButtonWithoutTextWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F1F4F7;
	border-radius: 4px;
	margin-left: 16px;
	button {
		padding: 8px;
	}
`;
const OrderToolBarButtonStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	color: #4B4F5E;
	background-color: #F5F5F5;
	border-radius: 4px;
	min-width: 43px;
	button {
		width: 100%;
	}
`;

const OrderPageWrapper = styled.div`
		width: 100%;
        .mobile-view {
          display: none;
        }
        @media (max-width: 767px) {
          .desktop-view {
            display: none;
          }
          .mobile-view {
            display: block;
          }
        }
      `;

const ToolBarButton = styled.span`
		height: 40px;
		width: 40px;
		background: var(--Neutral-Gray, #F1F4F7);
		border-radius: 4px;
		display: flex;
		padding: 8px;
		justify-content: center;
		align-items: center;
`;

const BUMP_VIEW_LAYOUT = 2;
const PauseWarningWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 24px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	background: #BC362F;
`;
const PauseWarningLeft = styled.div`
	& + div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	svg {
		margin-right: 8px;
	}
`;

const RefreshBtnWrapper = styled.div`
	svg {
		color: #4B4F5E;
	}
`;
const PauseWarningRight = styled.div``;

@inject("store") @observer
class RestaurantOrdersClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			quick_settings_active: false,
			bump_view_screen_active: false,
			// pause_services_active: false,
			filter_active: false,
			filter_list_active: false,
			toggleSpecialHoursModal: false,
			fetching_data_bump: false,
			countdownFinished: false,
			export_data: false,
			export_data_column: false,
			orderExport: {
				columns: {},
			},
			activeCol: [],
			tempColumns: {},
			checkResumeServices: false,
			display_settings_active: false,
			displaySettings: {
				showUnconfirmColumn: false,
				isCancelledChecked: false,
				layoutView: 'expanded',
				cardView: 'card',
			},
		};
	}

	changeView = (layout: 0 | 1 | 2) => {
		runInAction(() => {
			const { store } = this.injected;
			const r = store.restaurant!;
			if (store.ordersView.layout === layout) {
				return;
			}

			store.ordersView.layout = layout;
			store.ordersBoard.lists = {};
			store.orders.items = [];
			store.orders.count = 0;

			const settings = localStore.get("store-ordersView") || {};
			settings.layout = layout;
			localStore.set("store-ordersView", settings);
			if (layout === 0) {
				store.router.push(`/restaurant/${r._id}/orders`);
			}
			if (layout === 1) {
				store.router.push(`/restaurant/${r._id}/orders/list`);
			}
			if (layout === 2) {
				store.router.push(`/restaurant/${r._id}/orders/bump`);
			}
		});
	}

	componentDidMount(): void {
		const { currentLayout = 0 } = this.props;
		this.changeView(currentLayout);
		const restaurant = this.injected.store.restaurant!;

		let orderExport;
		if (restaurant.settings.order_export) {
			orderExport = cloneDeepSafe(restaurant.settings.order_export);

			//handle case with new Checkout Field column
			if (!orderExport.columns.Custom_Checkout_Fields) {
				orderExport.columns.Custom_Checkout_Fields = {
					name: 'Custom Checkout Field',
					active: false,
				};
			}
		} else {
			orderExport = {
				columns: exportColumns,
			};
		}

		const tempColumns = orderExport.columns;

		let activeCol: string[] = [];
		Object.values(orderExport.columns).forEach(c => {
			if (c.active) activeCol.push(c.name);
		});

		this.setState({ activeCol, tempColumns, orderExport });

	}
	componentWillUnmount(): void {
		this.injected.store.updateTempararyPauseEnd(0);
	}

	changeBoardLayout = (size: 1 | -1) => {
		runInAction(() => {
			const { store } = this.injected;
			const current = store.ordersView.boardSize;
			const currentV2 = store.ordersView.boardSizeV2;
			const next = current + size;
			const nextV2 = currentV2 + size;
			if (store.organisation && store.organisation.dashboard === 'v1' && (next > 5 || next < 2)) {
				return;
			}
			if (store.organisation && store.organisation.dashboard === 'v2' && (nextV2 > 6 || nextV2 < 2)) {

				return;
			}
			if (!store.restaurant!.settings.services.delivery.enabled && next > 4) {
				return;
			}

			store.ordersView.boardSize = next as 2 | 3 | 4 | 5;

			store.ordersView.boardSizeV2 = nextV2 as 2 | 3 | 4 | 5 | 6;

			if (store.organisation && store.organisation.dashboard === 'v1') {
				const settings = localStore.get("store-ordersView") || {};
				settings.boardSize = next.toString();
				localStore.set("store-ordersView", settings);
			} else if (store.organisation && store.organisation.dashboard === 'v2') {
				const settingsV2 = localStore.get("store-ordersView-v2") || {};
				settingsV2.boardSizeV2 = nextV2.toString();
				localStore.set("store-ordersView-v2", settingsV2);
			}
		});

	}

	changeHideUnconfirmed = () => {
		runInAction(() => {
			const { store } = this.injected;
			const val = !store.ordersView.hideUnconfirmed;
			store.ordersView.hideUnconfirmed = val;
			const settings = localStore.get("store-ordersView") || {};
			settings.hideUnconfirmed = val;
			localStore.set("store-ordersView", settings);

		});
	}

	onSearchCompleted = (orderId: string) => {
		const { store } = this.injected;
		const r = store.restaurant;
		store.router.push(`/restaurant/${r._id}/orders?_id=${orderId}`);
	}

	handleOpenSpecialHour = () => {
		const { store } = this.injected;
		store.updateOrderControl({ openSpecialHourModal: true })
	}

	onSearchFailed = () => {
		UI.notification.warning('Cannot find order with given Lalamove order ID');
	}
	handleFetchOrderBump = async () => {
		const { store } = this.injected;
		const r = store.restaurant;
		this.setState({ fetching_data_bump: true });
		const orderData = await getAllOrders(store, r._id as string);
		if (orderData && orderData.hasOwnProperty('count')) {
			this.setState({ fetching_data_bump: false });
		}
	}

	handleFilterViewScreenClose = () => {
		this.setState({ filter_active: false });
	}
	handleFilterListViewScreenClose = () => {
		this.setState({ filter_list_active: false });
	}

	handleExportDataClose = () => {
		this.setState({ export_data: false });
	}

	handleExportDataColumnClose = () => {
		this.setState({ export_data_column: false });
	}
	handleDisplaySettings = (settings: any) => {
		this.setState({ displaySettings: settings })
	}

	handleSubmit = async () => {
		const { store } = this.injected;
		const r = store.restaurant!;
		const { activeCol } = this.state;
		const tempColumns = this.state.tempColumns;

		Object.values(tempColumns).forEach(c => {
			if (activeCol.includes(c.name)) {
				c.active = true;
			} else {
				c.active = false;
			}
		});

		const order_export_temp = {
			columns: tempColumns,
		};

		const apiResult = await store.api.restaurant_update({
			_id: r._id,
			update: {
				$set: {
					'settings.order_export': order_export_temp,
				},
			},
		});

		if (apiResult.outcome === 0) {
			r.settings.order_export = order_export_temp;
			this.setState({
				orderExport: order_export_temp,
				export_data_column: false
			});
		}
	};

	updateTempColumns = (tempColumns: any) => {
		this.setState({
			tempColumns
		})
	}

	updateActiveCol = (colNames: string[]) => {
		this.setState({
			activeCol: colNames
		})
	}




	render() {
		const { store } = this.injected;
		const { showMainSupport, restrictions, orderControl } = store;
		const { layout, boardSize, boardSizeV2, hideUnconfirmed } = store.ordersView;
		const r = store.restaurant!;
		const useAwaitingPayment = !!r.settings.business.using_awaiting_payment;
		const { openPauseServiceModal, openSpecialHourModal } = orderControl;
		let actualBoardSize = boardSize - (hideUnconfirmed && !useAwaitingPayment ? 1 : 0);
		actualBoardSize = actualBoardSize < 2 ? 2 : actualBoardSize;
		let actualBoardSizeV2 = boardSizeV2 - (hideUnconfirmed && !useAwaitingPayment ? 1 : 0);
		actualBoardSizeV2 = actualBoardSizeV2 < 2 ? 2 : actualBoardSizeV2;
		const containerClass = layout === 0 ? "center" : "";
		let quickSettingsEnabled = false;
		if (restrictions._.restaurantSettingsEnabled) {
			const sd = restrictions.restaurant.settings_detail;
			if (sd && sd.services) {
				quickSettingsEnabled = true;
			}
			else {
				quickSettingsEnabled = !!restrictions.restaurant.settings;
			}
		}
		const lalamoveEnabled = RestaurantUtils.settings.integrationLalamoveActive(r!);

		const pausingTime = getPauseTime(r, store.tempPausedEnd);
		const services = getPauseServices(r);
		const { countdownFinished, orderExport } = this.state;
		const getToolTipPauseBtn = (pauseLabel: string = "", resumeLabel: string = "") => {
			return (pausingTime <= 0 || countdownFinished) ? pauseLabel : resumeLabel;
		};
		const handleSetResumeService = (resumseStatus: boolean) => {
			this.setState({checkResumeServices: resumseStatus})
		};
		const renderPauseServiceBtn = () => {
			return (
					<div>
						{pausingTime <= 0 && (
						<>
								<Tooltip text="Pause Services" width={100} position="bottom">
									<IoIosPause />
								</Tooltip>
						</>
						)}
						{pausingTime > 0 && (
						<>
								<Tooltip text="Resume Services" width={120} position="bottom">
									<div onClick={()=> this.setState({checkResumeServices: true})}>
										<FaPlay />
									</div>
								</Tooltip>
						</>
						)}
						{this.state.checkResumeServices && <Modal
							width="sm"
							close={() => this.setState({ checkResumeServices: false })}
							closeButton={false}
							active={this.state.checkResumeServices}
						>
							<ModalContent className="flex-l-r-center">
								<h4 className="">
									Resume Service
								</h4>
							</ModalContent>
								<p className="p-lr-4">Would you like to resume {services}</p>
							<ModalContent className="flex-right">
								<Button
									type="button"
									className="width100 max100px m-r-2"
									color="primary"
									onClick={handleResumeServices}
								>
									Yes
								</Button>
								<Button
									type="button"
									className="width100 max100px"
									onClick={() => this.setState({ checkResumeServices: false })}
								>
									Cancel
								</Button>
							</ModalContent>
						</Modal>}
					</div>
			);
		};

		const renderPauseServiceBtn2 = () => {
			return (<>
				{(pausingTime <= 0 || countdownFinished) && (
					<Tooltip
						text="Pause Services"
						width={130}
						position="bottom"
						style={{ paddingLeft: 8, paddingRight: 8 }}
					>
						<PauseIcon />
					</Tooltip>
				)}
				{pausingTime > 0 && !countdownFinished && (
					<Tooltip
						text="Resume Services"
						width={130}
						position="bottom"
						style={{ paddingLeft: 8, paddingRight: 8 }}
					>
						<div onClick={()=> this.setState({checkResumeServices: true})}>
							<ResumeIcon/>
						</div>
					</Tooltip>
				)}
					{this.state.checkResumeServices && <Modal
						width="sm"
						close={() => this.setState({ checkResumeServices: false })}
						closeButton={false}
						active={this.state.checkResumeServices}
					>
						<ModalContent className="flex-l-r-center">
							<h4 className="">
								Resume Service
							</h4>
						</ModalContent>
							<p className="p-lr-4">Would you like to resume {services}</p>
						<ModalContent className="flex-right">
							<Button
								type="button"
								className="width100 max100px m-r-2"
								color="primary"
								onClick={handleResumeServices}
							>
								Yes
							</Button>
							<Button
								type="button"
								className="width100 max100px"
								onClick={() => this.setState({ checkResumeServices: false })}
							>
								Cancel
							</Button>
						</ModalContent>
					</Modal>}
			</>
			)
		}

		const handleResumeServices = async () => {
			this.setState({ countdownFinished: true, checkResumeServices: false});
			await store.api.restaurant_update_operation({
				_id: r._id || '',
				status: 'resume',
				duration: 0,
				services: [], // add any of the following: "pickup", "delivery", "dine_in", "booking"
				reason: "",
			});
			await store.service.restaurant.get();

		}
		const showCountdown = (layout === 0 || layout === 2) && !countdownFinished && pausingTime > 0;
		const pathNameLength = store.router.s.path.split("/").length - 1;
		const pathNameEnd = store.router.s.path.split("/")[pathNameLength];
		const formats = store.restaurant && store.restaurant.settings.region.formats;

		return (
			<div className={containerClass} style={{
				height: "100%",
				position: 'relative'
			}}>

				{/* <div className="flex-l-r-center" style={{ minWidth: "450px", height: TOOLBAR_HEADER_HEIGHT}}> */}
				<div className="flex-l-r-center" style={{ minWidth: "450px" }}>

					<OrderPageWrapper className={(store.organisation && store.organisation.dashboard || '')}
					 	style={{
							display: store.organisation && store.organisation.dashboard === 'v2.1' ? 'none' : 'block'
						}}>
						<OrderToolBarWrapper className="flex-line centered">
							<ModeButtonWrapper>
								{restrictions._.restaurantOrderViews.length > 1 && (
									<div style={{ display: "flex" }}>
										{restrictions._.restaurantOrderViews.indexOf("board") !== -1 && (
											<ModeButtonStyle className="m-r-1">
												<Button
													size="sm"
													color={layout === 0 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey round-button-sm"
													onClick={() => this.changeView(0)}>
													Orders
												</Button>
											</ModeButtonStyle>
										)}
										{(restrictions._.restaurantOrderViews.indexOf("list") !== -1 && store.organisation && store.organisation.dashboard != 'v2.1') && (
											<ModeButtonStyle className="m-lr-1" onClick={() => this.changeView(1)}>
												<Button
													size="sm"
													color={layout === 1 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey round-button-sm"
												>
													List
												</Button>
											</ModeButtonStyle>
										)}

										{(restrictions._.restaurantOrderViews.indexOf("bump") !== -1 && store.organisation && store.organisation.dashboard != 'v2.1') &&
											(
												<ModeButtonStyle className="m-l-1">
													<Button
														size="sm"
														color={layout === 2 ? "primary" : "transparent"}
														className="p-lr-1 bg.grey"
														onClick={() => this.changeView(2)}>
														Bump Screen
													</Button>
												</ModeButtonStyle>
											)
										}

								</div>
							)}
						</ModeButtonWrapper>

						<ControlButtonWrapper>
							{lalamoveEnabled && (
								<LalamoveOrderSearch
									restaurantId={r._id || ''}
									onSearchCompleted={this.onSearchCompleted}
									onSearchFailed={this.onSearchFailed}
								/>
							)}
							{layout === 0 && (
								<div className="m-l-1" style={{ display: "flex" }}>
									<OrderToolBarButtonStyle>
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round-right no-border-r"
											onClick={() => this.changeBoardLayout(-1)}>
											<FaMinus />
										</Button>
									</OrderToolBarButtonStyle>
									<OrderToolBarButtonStyle className="m-lr-1">
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round no-border-r"
											onClick={() => this.changeBoardLayout(1)}>
											<FaPlus />
										</Button>
									</OrderToolBarButtonStyle>
									<OrderToolBarButtonStyle>
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 no-round-left"
											onClick={() => this.changeHideUnconfirmed()}>
											<FaEye />
										</Button>
									</OrderToolBarButtonStyle>
								</div>
							)}
							<div style={{ display: "flex" }}>
								{(layout === 0 || layout === 2) && (
									<OrderToolBarButtonStyle className="m-l-1">
										<Button
											size="sm"
											color={"transparent"}
											className="p-lr-1 m-lr-1 no-round-left"
											onClick={() =>{
												console.log(pausingTime);
												pausingTime > 0 || countdownFinished ? handleResumeServices() : store.updateOrderControl({ openPauseServiceModal: true })
											}
												
											}>
											{renderPauseServiceBtn()}
										</Button>
									</OrderToolBarButtonStyle>
								)}
							</div>


							<div className="m-l-1" style={{ display: "flex" }}>
								{(layout === 2 && (
									<>
										<ButtonWithoutTextWrapper>
											<Tooltip text="Filter" width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className={showMainSupport ? "no-round-right no-border-r" : "p-lr-1"}
													onClick={() => this.setState({ filter_active: true })}>
													<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><FilterIcon /></div>
												</Button>
											</Tooltip>
										</ButtonWithoutTextWrapper>
										<ButtonWithoutTextWrapper>
											<Tooltip text="View" width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className={showMainSupport ? "no-round-right no-border-r" : "p-lr-1"}
													onClick={() => this.setState({ bump_view_screen_active: true })}>
													<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><ViewIcon /></div>
												</Button>
											</Tooltip>
										</ButtonWithoutTextWrapper>
										<ButtonWithoutTextWrapper>
											<Tooltip text={getToolTipPauseBtn("Pause Services", "Resume Services")} width={65} position="bottom">
												<Button
													size="sm"
													color={"transparent"}
													className="no-round-left"
													onClick={() =>
														{
															console.log(pausingTime);
															pausingTime > 0 ? handleResumeServices() : store.updateOrderControl({ openPauseServiceModal: true });
														
														}
													}>
													{renderPauseServiceBtn()}
												</Button>
											</Tooltip>
										</ButtonWithoutTextWrapper>
									</>
								))}
								{(layout === 1 && (
									<>
										<OrderToolBarButtonStyle className="m-lr-2">
											<Button
												size="sm"
												color={"transparent"}
												className={showMainSupport ? "p-lr-1 m-l-1 no-round-right no-border-r" : "p-lr-1"}
												onClick={() => this.setState({ filter_list_active: true })}>
												<div style={{ display: "flex", alignItems: "center", gap: "8px" }}><FilterIcon /> Filter </div>
											</Button>
										</OrderToolBarButtonStyle>
									</>
								))}

								{quickSettingsEnabled && (layout === 0 || layout === 1 ) && (
									<OrderToolBarButtonStyle className="m-r-1">
										<Tooltip text="Settings" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={showMainSupport ? "p-lr-1 no-round-right no-border-r" : "p-lr-1"}
												onClick={() => this.setState({ quick_settings_active: true })}>
												<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
													<FaCog />
												</div>
											</Button>
										</Tooltip>
										
									</OrderToolBarButtonStyle>
								)}
								{layout === 0 && (
									<OrderToolBarButtonStyle>
										<Tooltip text="Refresh" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={store.service.order.get_board}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}>
													<FaSync />
												</RefreshBtnWrapper>
											</Button>
										</Tooltip>
										
									</OrderToolBarButtonStyle>
								)}
								{layout === 1 && (
									<ButtonWithoutTextWrapper>
										<Tooltip text="Filter" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={() => this.setState({ filter_list_active: true })}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><FilterIcon /></RefreshBtnWrapper>
											</Button>
										</Tooltip>
									</ButtonWithoutTextWrapper>
								)}
								{layout === 2 && (
									<OrderToolBarButtonStyle>
										<Tooltip text="Refresh" width={65} position="bottom">
											<Button
												size="sm"
												color={"transparent"}
												className={cn("", showMainSupport && "no-round-right no-border-r", quickSettingsEnabled && "no-round-left")}
												onClick={this.handleFetchOrderBump}>
												<RefreshBtnWrapper style={{ display: "flex", alignItems: "center", gap: "8px" }}><FaSync /></RefreshBtnWrapper>
											</Button>
										</Tooltip>
									</OrderToolBarButtonStyle>
								)}
								{showMainSupport && (layout === 0 || layout === 1) && (
									<OrderToolBarButtonStyle className="m-l-1">
										<Tooltip text="Help" width={65} position="bottom">
											<a target="_blank" href="https://support.cloudwaitress.com/getting-started/managing-orders-and-bookings">
												<Button size="sm" color="transparent" className="no-round-left">
													<FaQuestionCircle />
												</Button>
											</a>
										</Tooltip>
									</OrderToolBarButtonStyle>
								)}
							</div>
						</ControlButtonWrapper>

						</OrderToolBarWrapper>
					</OrderPageWrapper>

				</div>

				{(store.organisation &&
					store.organisation.dashboard &&
					store.organisation.dashboard == 'v2.1') && (
						<SettingsHeader style={{ background: '#fff' }}>
							{restrictions._.restaurantOrderViews.length > 1 && (
									<div style={{ display: "flex" }}>
										{restrictions._.restaurantOrderViews.indexOf("board") !== -1 && (
											<ModeButtonStyle className="m-r-1">
												<Button
													size="sm"
													color={layout === 0 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey round-button-sm"
													onClick={() => {this.changeView(0)}}>
													Orders
												</Button>
											</ModeButtonStyle>
										)}
										{(restrictions._.restaurantOrderViews.indexOf("list") !== -1) && (
											<ModeButtonStyle className="m-lr-1" onClick={() => {this.changeView(1)}}>
												<Button
													size="sm"
													color={layout === 1 ? "primary" : "transparent"}
													className="p-lr-1 bg.grey round-button-sm"
												>
													List
												</Button>
											</ModeButtonStyle>
										)}

										{(restrictions._.restaurantOrderViews.indexOf("bump") !== -1 ) &&
											(
												<ModeButtonStyle className="m-l-1">
													<Button
														size="sm"
														color={layout === 2 ? "primary" : "transparent"}
														className="p-lr-1 bg.grey"
														onClick={() => {this.changeView(2)}}>
														Bump Screen
													</Button>
												</ModeButtonStyle>
											)
										}

								</div>
							)}
							<SettingsHeaderMenuContainer style={{ gap: 16, background: '#fff' }}>
								<ToolBarButton
									onClick={() =>
										{
											(pausingTime <= 0 || countdownFinished) && store.updateOrderControl({ openPauseServiceModal: true })
										}
										
									}>
									{renderPauseServiceBtn2()}
								</ToolBarButton>
								<ToolBarButton onClick={() => this.handleOpenSpecialHour()}>
									<Tooltip
										text="Special Hours"
										width={130}
										position="bottom"
									>
										<SpecialHoursIcon />
									</Tooltip>
								</ToolBarButton>

								{(this.state.displaySettings.cardView === 'order' || layout === 0 ) && <ToolBarButton
									onClick={() => this.setState({ display_settings_active: true })}>
									<Tooltip
										text="Display Settings"
										width={130}
										position="bottom">
										<FilterOrderIcon />
									</Tooltip>
								</ToolBarButton>}
								<ToolBarButton
									onClick={() => this.setState({ quick_settings_active: true })}>
									<Tooltip
										text="Settings"
										width={130}
										position="bottom">
										<IconSettings />
									</Tooltip>
								</ToolBarButton>
								{(this.state.displaySettings.cardView === 'list' || layout === 1 ) && 
								<>
									<ToolBarButton
										onClick={() => this.setState({ filter_list_active: true })}>
										<Tooltip text="Filter" width={65} position="bottom">
											<FilterIcon />
										</Tooltip>
									</ToolBarButton>
									<ToolBarButton
										onClick={() => this.setState({ export_data: true })}>
											<Tooltip text="Export" width={65} position="bottom">
												<FaDownload />
											</Tooltip>
									</ToolBarButton>
								</>
								}
								{(this.state.displaySettings.cardView === 'bump' || layout === 2 ) && 
								<>
									<ToolBarButton
										onClick={() => this.setState({ filter_active: true })}>
										<Tooltip text="Filter" width={65} position="bottom">
											<FilterIcon />
										</Tooltip>
									</ToolBarButton>
									<ToolBarButton
										onClick={() => this.setState({ bump_view_screen_active: true })}>
											<Tooltip text="View" width={65} position="bottom">
												<ViewIcon />
											</Tooltip>
									</ToolBarButton>
									<ToolBarButton
										onClick={this.handleFetchOrderBump}>
											<Tooltip text="Refresh" width={65} position="bottom">
												<FaSync />
											</Tooltip>
									</ToolBarButton>
								</>
								
								}
								<ToolBarButton onClick={() => window.open('https://support.cloudwaitress.com/getting-started/managing-orders-and-bookings', '_blank')}>
									<Tooltip
										text="Help"
										width={130}
										position="bottom">
										<QuestionCircleIcon />
									</Tooltip>
								</ToolBarButton>
							</SettingsHeaderMenuContainer>
						</SettingsHeader>)}
				{showCountdown && <PauseWarningWrapper>
					<PauseWarningLeft>
						<CommonText size="semiMedium" weight={500} color="#F5F5F5" style={{ display: 'flex', alignItems: 'center'}}>
							<FaHourglassHalf style={{ width: '20px', height: '20px'}}/>
							{services} are paused
						</CommonText>
					</PauseWarningLeft>
					<PauseWarningRight>
						<CountdownClock endTime={store.tempPausedEnd ? store.tempPausedEnd : r.settings.business.operations?.pause_end ? r.settings.business.operations?.pause_end : 0} finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }} />
					</PauseWarningRight>
				</PauseWarningWrapper>}
				<LayoutViewWrapper customStyle={pathNameEnd}>

					{(layout === 0 && store.organisation && store.organisation.dashboard != 'v2.1') && <RestaurantOrdersBoard />}
					{(layout === 0 && store.organisation && store.organisation.dashboard == 'v2.1') && 
					<RestaurantOrdersBoardV2 
						handleOpenSpecialHour={this.handleOpenSpecialHour} 
						layout={this.state.displaySettings.cardView || 'card'} 
						hideCancelledComlumn={this.state.displaySettings.isCancelledChecked || false} 
						hideUnconfirmColumn={this.state.displaySettings.showUnconfirmColumn || false}
						layoutView={this.state.displaySettings.layoutView === 'expanded' ? 6 : 3}
						handleSetResumeService={handleSetResumeService}
						checkResumeServices={this.state.checkResumeServices}
						handleResumeServices={handleResumeServices}
						handleQuickSettings={() => this.setState({ quick_settings_active: true})}
					/>}

					{(layout === 1 && store.organisation && store.organisation.dashboard != 'v2.1') && <RestaurantOrdersList />}
					{(layout === 1 && store.organisation && store.organisation.dashboard == 'v2.1') 
					&& 
						<RestaurantOrderWrapper className="restaurant-order">
							<div className="desktop-view"><RestaurantOrdersListV2 /></div>
							<div className="mobile-view restaurant-order">
							<RestaurantOrdersBoardV2 
								handleOpenSpecialHour={this.handleOpenSpecialHour} 
								layout={this.state.displaySettings.cardView || 'card'} 
								hideCancelledComlumn={this.state.displaySettings.isCancelledChecked || false} 
								hideUnconfirmColumn={this.state.displaySettings.showUnconfirmColumn || false}
								layoutView={this.state.displaySettings.layoutView === 'expanded' ? 6 : 3}
								handleSetResumeService={handleSetResumeService}
								checkResumeServices={this.state.checkResumeServices}
								handleResumeServices={handleResumeServices}
								handleQuickSettings={() => this.setState({ quick_settings_active: true})}
							/>
							</div>
						</RestaurantOrderWrapper>
					
				 }
				 {(layout === 2 &&  restrictions._.restaurantOrderViews.indexOf("bump") !== -1 && store.organisation && store.organisation.dashboard == 'v2.1') 
					&& 
						<RestaurantOrderWrapper className="restaurant-order">
							<div className="desktop-view" style={{ padding: 0}}><RestaurantOrdersBump fetchingDataBump={this.state.fetching_data_bump} /></div>
							<div className="mobile-view restaurant-order">
							<RestaurantOrdersBoardV2 
								handleOpenSpecialHour={this.handleOpenSpecialHour} 
								layout={this.state.displaySettings.cardView || 'card'} 
								hideCancelledComlumn={this.state.displaySettings.isCancelledChecked || false} 
								hideUnconfirmColumn={this.state.displaySettings.showUnconfirmColumn || false}
								layoutView={this.state.displaySettings.layoutView === 'expanded' ? 6 : 3}
								handleSetResumeService={handleSetResumeService}
								checkResumeServices={this.state.checkResumeServices}
								handleResumeServices={handleResumeServices}
								handleQuickSettings={() => this.setState({ quick_settings_active: true})}
							/>
							</div>
						</RestaurantOrderWrapper>
					
				 }


					{(layout === 2 && store.organisation && store.organisation.dashboard !== 'v2.1' &&  restrictions._.restaurantOrderViews.indexOf("bump") !== -1)  &&
					 <OrderBumpWapper><RestaurantOrdersBump fetchingDataBump={this.state.fetching_data_bump} /></OrderBumpWapper>}
				</LayoutViewWrapper>

				<RestaurantOrderModal />

				{/* <RestaurantOrdersQuickSettings
					active={this.state.quick_settings_active}
					close={() => this.setState({ quick_settings_active: false })}
				/> */}
				<RestaurantOrdersQuickSettingsV2
					active={this.state.quick_settings_active}
					close={() => this.setState({ quick_settings_active: false })}
				/>

				<BumpViewSidebar
					isOpen={this.state.bump_view_screen_active}
					close={() => this.setState({ bump_view_screen_active: false })}
				/>

				{ store.organisation?.dashboard !== 'v2.1' ?
				<PauseServicesSidebar
					isOpen={openPauseServiceModal}
					close={() => store.updateOrderControl({ openPauseServiceModal: false })}
					finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }}
				/> : <PauseServicesSidebarV2
					isOpen={openPauseServiceModal}
					close={() => store.updateOrderControl({ openPauseServiceModal: false })}
					finishCountdown={(value: boolean) => { this.setState({ countdownFinished: value }) }}
				/> }

				{ store.organisation?.dashboard !== 'v2.1' ?
				<Sidebar
					isOpen={openSpecialHourModal}
					headerTitle='Special Hours'
					optional='(optional)'
					version='v2'
					onClose={() => store.updateOrderControl({ openSpecialHourModal: false })}
					width={window.innerWidth < 420 ? window.innerWidth : 420}
				>
					<RestaurantSpecialHours
						show={this.state.toggleSpecialHoursModal}
						toggleSpecialHoursModal={() => this.setState(prev => ({
							toggleSpecialHoursModal: !prev.toggleSpecialHoursModal
						}))} />
				</Sidebar> : 
				<RestaurantSpecialHoursV2
				show={openSpecialHourModal}
				toggleSpecialHoursModal={() => store.updateOrderControl({ openSpecialHourModal: false })} />}

				<FilterViewScreen
					active={this.state.filter_active}
					close={this.handleFilterViewScreenClose}
				/>
				<FilterListViewV2
					isOpen={this.state.filter_list_active}
					handleClose={this.handleFilterListViewScreenClose}
				/>
				<DisplaySettings 
					active={this.state.display_settings_active}
					close={() => this.setState({ display_settings_active: false })}
					saveSettings={this.handleDisplaySettings}
					displaySettings={this.state.displaySettings}
				/>

				<ExportDataSidebar
					isOpen={this.state.export_data}
					handleClose={this.handleExportDataClose}
					configExport={config}
					token={store.auth.token}
					formats={formats}
					restaurantId={store.restaurant!._id}
					handleOpenExportDataColumn={() => this.setState({ export_data_column: true })}
				/>

				{this.state.export_data_column && (<ExportReportColumnModal
					isOpen={this.state.export_data}
					handleClose={this.handleExportDataColumnClose}
					orderExportData={this.state.orderExport}
					handleSubmit={this.handleSubmit}
					setTempColumns={this.updateTempColumns}
					activeCol={this.state.activeCol}
					setActiveCol={this.updateActiveCol}

				/>)}




			</div>
		);
	}

}

export const RestaurantOrders = withTranslation()(RestaurantOrdersClass);
