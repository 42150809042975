import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, CommonText, FooterSection, Input, Sidebar } from '@lib/components';
import { Translation } from "react-i18next";
import { CircleDollarIcon, ClockIconBlack, ForkAndKniveIcon, IconBack, IconCalendarAlt } from '../../../../../components/v2/icons';
import { MobXProviderContext } from 'mobx-react';
import moment from "moment-timezone";
import DayPicker, { DateUtils as DateMore } from "react-day-picker";
import { MobileModal, MobileModalBody, MobileModalContent, MobileModalFooter, MobileModalHeader } from '../../settings-v2';

const DatePickerFooter = styled("div")`
    padding: 0 2em;
    background-color: #F5F5F5;
    height: 6vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`
const DateRangeWrapper = styled("div")`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    background: var(--Neutral-White, #FEFEFE);
    
    input {
        border: unset;
        width: 100%;
        color: var(--Secondary-Dark-Grey-1, #4B4F5E);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 24px; /* 171.429% */
        &:focus, &:focus-visible {
            outline: none;
        }
    }
`

const InputWrapper = styled("div")`
  position: relative;
  width: 100%;
  & > svg {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        display: inline-block;
    }
  input{
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    padding: 8px 12px 8px 43px;
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`

const Wrapper = styled("div")`
  position: relative;
  
  svg {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        display: inline-block;
    }
  
    .DayPicker{
        width: 100%;
        background-color: #F5F5F5;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        .DayPicker-NavButton{
            width: 3.5em;
            height: 2.25em;
            top: 0.5em;
        }
        .DayPicker-NavButton--prev{
            left: 1.5rem;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTE1LjcwNSA3LjQxTDE0LjI5NSA2TDguMjk1MDQgMTJMMTQuMjk1IDE4TDE1LjcwNSAxNi41OUwxMS4xMjUgMTJMMTUuNzA1IDcuNDFaIiBmaWxsPSIjNDk0NTRGIi8+Cjwvc3ZnPg==");
        }
        .DayPicker-NavButton--next{
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTkuNzA1MDQgNkw4LjI5NTA0IDcuNDFMMTIuODc1IDEyTDguMjk1MDQgMTYuNTlMOS43MDUwNCAxOEwxNS43MDUgMTJMOS43MDUwNCA2WiIgZmlsbD0iIzQ5NDU0RiIvPgo8L3N2Zz4=")
        }
        .DayPicker-Months{
            height: calc(100vh - 144px);
            overflow: auto;
            padding-bottom: 87px;
            gap: 5%;
        }
        .DayPicker-Month{
            width: 100%;
            height: 40%;
            .DayPicker-Caption{
                text-align: center;
                margin-top: 0.5em;
                margin-bottom: 1.5em;
                & > div {
                    color: #49454F;
                }
            }
            .DayPicker-Week .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                background-color: #FB2F2F !important;
            }
            .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                background-color: #FF7979 ;
                border-radius: 0px;
            }
            .DayPicker-Day--start:not(.DayPicker-Day--outside){
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                background-color: #FB2F2F !important;
            }
            .DayPicker-Day--end:not(.DayPicker-Day--outside){
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
                background-color: #FB2F2F !important;
            }
        }
    }
`;

export const DateRangePickerV2: React.FC<any> = (props) => {
    const { disabled, onSelect, displayFormat = 'DD/MM/YYYY', timezone = moment.tz.guess(), className, numberOfMonths = 1, modifiers, rangeDate = {
        from: undefined,
        to: undefined
    } } = props;
    const { store } = React.useContext(MobXProviderContext);
    const [openDateModal, setOpenDateModal] = useState(false)

    const [range, setRange] = useState<any>(rangeDate)

    const handleSelectDate = (day: Date) => {
        const selectedRange = DateMore.addDayToRange(day, range);
        if(disabled && (day > disabled.before || day < disabled.after) || !disabled){
            setRange(selectedRange);
        } 
    }

    const fromValue = moment.tz(rangeDate.from, timezone).format(displayFormat);
    const toValue = moment.tz(rangeDate.to, timezone).format(displayFormat);

    const displayValue = rangeDate ? fromValue === toValue ? `${fromValue}` : `${fromValue} - ${toValue}` : ''
    return (
        <DateRangeWrapper>
            <InputWrapper>
                <IconCalendarAlt />
                <Input
                    readOnly={true}
                    value={displayValue}
                    onFocus={() => {
                        setOpenDateModal(true)
                        setRange(rangeDate)
                    }}
                />
            </InputWrapper>
            {openDateModal &&
            <MobileModal className='dashboard-filter-modal full-mobile'>

            
                <MobileModalContent>
                    <MobileModalHeader style={{justifyContent: 'start'}}>
                        <span onClick={() => setOpenDateModal(false)}><IconBack /></span>
                        Add Special Hours
                    </MobileModalHeader>
                    <MobileModalBody style={{paddingTop: 0}}>
                    <Wrapper>
                        {range ? <DayPicker
                            selectedDays={[range.from, { from: range.from, to: range.to }]}
                            onDayClick={handleSelectDate}
                            disabledDays={disabled}
                            numberOfMonths={numberOfMonths}
                            modifiers={{ start: range.from, end: range.to }}
                        /> : ''}
                        
                    </Wrapper>
                    </MobileModalBody>
                    <MobileModalFooter style={{flexDirection: 'row', background: 'white'}}>
                    <Button type="button" full={true} paddinglr={5} color='primary' onClick={() => {
                        onSelect(range);
                        setOpenDateModal(false)
                    }}>
                        Set
                    </Button>
                    <Button type="button" full={true} paddinglr={5} onClick={() => {
                        setRange({
                            from: undefined,
                            to: undefined
                        });
                        setOpenDateModal(false)
                    }}>
                        Cancel
                    </Button>
                    </MobileModalFooter>
                </MobileModalContent>
            </MobileModal>}
        </DateRangeWrapper>

    );
};