import React, { useState } from 'react';
import { Button, CommonText, DateInputV2, DatePicker, FooterSection } from '@lib/components';
import { Header, ModalWrapper } from '../bookingDetail/bookingDetail.component';
import { BackIcon } from '../bookingDetail/bookingDetail.icon';
import { IconSettings, QuestionIcon } from '../../../../../../../components/v2/icons';
import styled from 'styled-components';
import DayPicker from "react-day-picker";
import { ModalBody } from '../settings/bookingSettingConstants';
import { MobXProviderContext } from 'mobx-react';
import { format } from 'date-fns'
import { GroupItemsTick } from '../../../../../components/groupItemsTick';
import { MobileModal } from '../../../../settings-v2';

const TimeRangeWrapper = styled.div`
    width: 100%;
    position: relative;
    .DayPicker {
        background: #fff;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
`;

const DateTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const ActiveInfo = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 15C10.2833 15 10.521 14.904 10.713 14.712C10.905 14.52 11.0007 14.2827 11 14V10C11 9.71667 10.904 9.479 10.712 9.287C10.52 9.095 10.2827 8.99933 10 9C9.71667 9 9.479 9.096 9.287 9.288C9.095 9.48 8.99933 9.71733 9 10V14C9 14.2833 9.096 14.521 9.288 14.713C9.48 14.905 9.71733 15.0007 10 15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99933 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99933 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z" fill="#FB2F2F" />
    </svg>
);


type CSVExportProps = {
    isOpen: boolean;
    handleClose: () => void;
}
type ModalState = "start" | "end" | "";

export const CSVExport: React.FC<CSVExportProps> = ({ isOpen, handleClose }) => {
    const [activeId, setActiveId] = useState('');
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
    const { store } = React.useContext(MobXProviderContext);

    const exportCustomData = async (startDate: Date, endDate: Date) => {
        const r = store.restaurant!;
        const rid = r._id;
        const response = await store.api.downloadCSVBooking(rid, startDate, endDate);
        // Create a Blob with the CSV data and type
        const blob = new Blob([response], { type: 'text/csv' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create an anchor tag for downloading
        const a = document.createElement('a');

        // Set the URL and download attribute of the anchor tag
        a.href = url;
        a.download = `booking ${format(startDate, store.restaurant.settings.region.formats.date.toLowerCase())}_${format(endDate, store.restaurant.settings.region.formats.date.toLowerCase())}.csv`;
        // Trigger the download by clicking the anchor tag
        a.click();
        a.remove();
    };

    if (!isOpen) {
        return <></>
    }
    const options = [
        {
            id: '7_days',
            label: 'Last 7 Days',
            value: 7
        },
        {
            id: '30_days',
            label: 'Last 30 Days',
            value: 30
        },
        {
            id: '90_days',
            label: 'Last 90 Days',
            value: 90
        },
        {
            id: 'all',
            label: 'All Time',
            value: 1000
        },
        {
            id: 'custom',
            label: 'Custom',
            value: 'custom'
        }
    ];

    const daysAgoMills = (days: number) => {
        return new Date(Date.now() - 1000 * 60 * 60 * 24 * days);
    };

    const handleSelectItem = (item: any) => {
        setActiveId(item.id);
        if (item.id === '7_days' || item.id === '30_days' || item.id === '90_days' || item.id === 'all') {
            const today = new Date()
            const lastXDay = daysAgoMills(item.value)
            setStartDate(lastXDay);
            setEndDate(today);
        }

    };


    const handleSelectFrom = (date: Date) => {
        setStartDate(date);
    };
    const handleSelectTo = (date: Date) => {
        setEndDate(date);
    };

    return (
        <MobileModal style={{overflow: 'auto'}}>
            <ModalWrapper bg='#FEFEFE'>
                <Header style={{
                    borderBottom: '1px solid #DFDFDF'
                }}>
                    <BackIcon onClick={handleClose} />
                    <CommonText size="medium" weight={600} color='#0C202A'>
                        CSV Data Export
                    </CommonText>
                    <div style={{
                        opacity: '0'
                    }}>
                        <IconSettings />
                    </div>
                </Header>
                <ModalBody gap={16} style={{height: 'calc(100vh - 144px)', paddingBottom: 87, overflow: 'auto'}}>
                    <Title>
                        <ActiveInfo />
                        <CommonText size="medium" weight={400} color='#FB2F2F'>
                            Note
                        </CommonText>
                    </Title>
                    <CommonText size="medium" weight={400} color='#6B7270'>
                        You can export all your data into CSV format which can be used by Microsoft Excel and other softwares. Exporting may take a long time if you have a large amount of data. It can also place a large load on our servers so please do not abuse this feature.
                    </CommonText>
                    <CommonText size="medium" weight={600} color='#4B4F5E'>
                        Export Date Range
                    </CommonText>
                    <GroupItemsTick options={options} activeId={activeId} selectItem={handleSelectItem} />
                    {activeId === 'custom' && <>
                        <TimeRangeWrapper>
                            <DateTitle>
                                <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                    From
                                </CommonText>
                                <QuestionIcon />
                            </DateTitle>

                            <DateInputV2 date={startDate} onSelect={handleSelectFrom} disabled={endDate && { after: endDate }} displayFormat={store.restaurant.settings.region.formats.date} />
                        </TimeRangeWrapper>
                        <TimeRangeWrapper>
                            <DateTitle>
                                <CommonText size="semiMedium" weight={600} color='#4B4F5E'>
                                    To
                                </CommonText>
                                <QuestionIcon />
                            </DateTitle>

                            <DateInputV2 date={endDate} onSelect={handleSelectTo} disabled={startDate && { before: startDate }} displayFormat={store.restaurant.settings.region.formats.date} />
                        </TimeRangeWrapper>
                    </>}
                </ModalBody>
                <FooterSection width={420}>
                    <Button size="md" full={true} type="button" onClick={() => {
                        handleClose()
                        setActiveId('')
                    }}>
                        Cancel
                    </Button>
                    <Button size="md" full={true} type="button" color="primary" onClick={() => exportCustomData(startDate, endDate)} >
                        Export Data
                    </Button>

                </FooterSection>
            </ModalWrapper>
        </MobileModal>
    );
};
