import React from 'react'
import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip"
export const LayoutContainer = styled.div`
    display: flex;
    align-items: flex-start;
`

export const SidebarContainer = styled.div`
    width: 60px;
    display: none;
    align-items: flex-start;
    align-self: stretch;
    border-right: 1px solid var(--secondary-light-grey-1, #DFDFDF);
    background: var(--neutral-white, #FEFEFE);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    flex-direction: column;
    position: fixed;
    z-index: 100;

    &.active {
        width: 300px;
        & .sidebar-menu-wrapper {
            
            & .menu-item-wrapper {
                width: 100%;
                & > *:not(svg) {
                    display: block;
                }
            }
        }
        & .sidebar-logo-wrapper {
            justify-content: space-between;
            & span, & .menu-item-wrapper {
                display: block;
            }
        }
    }

    & .menu-item-wrapper {
        & > *:not(svg) {
            display: none;
        }
    }

    @media (min-width: 768px) {
        display: flex;
        transition: width .2s ease;
    }
`

export const SidebarLogoWrapper = styled.div`
    display: flex;
    padding: 8px; 
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;

    & span {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */

        &.default-logo {
            display: block;
            svg {
                height: 40px;
            }
        }
    }

    & span, & .menu-item-wrapper {
        display: none;
    }
`
const MenuWrapper = styled.div`
    display: flex;
    padding: 0px 8px 8px 8px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin: 0;
`

export const SidebarMenuWrapper = styled(MenuWrapper)``

const MenuItemWrapper = styled.div<{ type?: number, pathNameEnd?: string }>`
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    transition: all .3s ease-in-out;
    cursor: pointer;
    padding: 8px;
    ${({ type }) => type === 2 ? css`
        position: absolute;
        left: 16px;
    ` : css``
    }
    & > svg path {
       fill: #4B4F5E;
       transition: all .3s ease-in-out;
    }

    &.active, &:hover, &:active, &:focus {
        background: var(--primary-accent-wisp-pink-1, #FFF3F3);
        transition: all .3s ease-in-out;
        & > svg path {
            fill: #FB2F2F;
            transition: all .3s ease-in-out;
        }

        /* & p {
            color: #FB2F2F;
        } */
       &.payment-method-style {
         background: var(--primary-accent-wisp-pink-1, #FFF);
       }
    }

    & > *:not(svg) {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
`

interface MenuItemProps {
    onClick?: React.MouseEventHandler<HTMLElement>;
    active?: boolean;
    tip?: string;
    style?: React.CSSProperties;
    type?: number;
    pathNameEnd?: string;
    className?: string;
}

export const MenuItem = (props: React.PropsWithChildren<MenuItemProps>) => {
    return (
        <MenuItemWrapper
            onClick={props.onClick} 
            className={`menu-item-wrapper ${props.active ? 'active' : ''}`}
            {...props}
            data-tip={props.tip}>
            {props.children}
            <ReactTooltip place="right" effect="solid" multiline={false} />
        </MenuItemWrapper>
    )
}

export const MainContainer = styled.div`
    width: 100%;
    transition: width .3s ease-in-out;
    position: relative;
    
    @media (min-width: 768px) {
        padding-left: 60px;
        transition: width .3s ease-in-out;
    }
    &.v2 {
        padding: 24px 24px 16px 24px;
    }

    // &:not(.v2) {
    //     max-width: 100%;
    //     padding-left: 0px;
    //     @media(min-width: 896px) {
    //         max-width: 896px;
    //     }
    // }
    
`

export const HeaderContainer = styled.div`
    display: none;
    opacity: 0;
    width: 100%;
    padding: 6px 24px 6px 24px;
    align-items: center;
    gap: 24px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    justify-content: space-between;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #DFDFDF;

    @media (min-width: 768px) {
        display: flex;
        opacity: 1;
        transition: all .3s ease-in-out;
    }

    padding-left: 0px;

    &.v2\.1 {
        padding-right: 8px;
    }
`
export const HeaderMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0;
    padding: 0;

    & > span {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
    }
`

export const HeaderMobile = styled.div<{ isMobile?: boolean; customStyle?: string }>`
    display: flex;
    opacity: 1;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    text-align: center;
    border-bottom: 1px solid var(--secondary-light-grey-1, #DFDFDF);
    background: var(--neutral-white, #FEFEFE);
    position: relative;
    transition: all .3s ease-in-out;
    flex-wrap: wrap;
    
    @media (min-width: 768px) {  
        display: none;
        opacity: 0;
        transition: all .3s ease-in-out;
    }
    
    @media (max-width: 768px) {
        position: ${({ customStyle }) => (['bookings', 'settings', 'customers'].includes(customStyle || '') ? 'fixed' : 'sticky')};
        width: 100%;
        top: 0;
        z-index: 2;
    }
`

export const HeaderMobileTitleWrapper = styled.div<{ isDevelopers?: boolean }>`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding-right: 0px; 
    align-items: center;
    gap: 8px;
`
export const HeaderTitleWrapper = styled(HeaderMobileTitleWrapper)``

export const HeaderMobileBackWrapper = styled.div`
    width: 100%;
    padding: 0px 0px 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);

    & div[class^="layoutc__MenuItemWrapper"] {
        padding-top: 0px;
    }
`


export const HeaderMobileLogoWrapper = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

export const HeaderMobileTitle = styled.h3`
    color: var(--secondary-dark-grey-1, #4B4F5E);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
`

export const HeaderMobileMenuContainer = styled.div`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--neutral-white, #FEFEFE);
    height: 0px;
    position: absolute;
    z-index: 9999999;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    transition: opacity 2s ease-in-out;
    border-bottom: 1px solid #ebebeb;

    &.active {
        display: flex;
        height: 100vh;
        opacity: 1;
        transition: opacity 2s ease-in-out;
        overflow-y: scroll;
        position: fixed;
    }
    & .HeaderMobileTitleWrapper {
        justify-content: start;
    }
`

export const HeaderMobileMenuUpperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`
export const HeaderMobileMenuLowerWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`

export const HeaderMobileMenuTitleWrapper = styled.div`
    display: flex;
    padding: 12px 16px;
    justify-content: start;
    align-items: center;
    align-self: stretch;
    position: relative;
`

export const HeaderMobileMenuWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    & .menu-item-wrapper {
        width: 100%;
    }
`

export const QuickSettingsContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 424px;
    width: 100%;
    background: var(--neutral-white, #FEFEFE);
    position: fixed;
    right: -9999px;
    top: 0;
    z-index: 100;
    height: 100%;
    transition: all .5s ease;

    &.active {
        right: 0px;
        transition: all .5s ease;
    }
`

export const QuickSettingsHeaderWrapper = styled.div`
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`
export const QuickSettingsBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    background: var(--neutral-white, #FEFEFE);
    width: 100%;
`

export const QuickSettingsFooterWrapper = styled.div`
    display: flex;
    padding: 12px 16px 24px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 1px solid var(--secondary-light-grey-1, #DFDFDF);
    background: var(--neutral-white, #FEFEFE);
`


export const ButtonV2 = styled.button`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--primary-red-orange-1, #FB2F2F);
    border:1px solid var(--primary-red-orange-1, #FB2F2F);
    color: var(--neutral-gray, #F1F4F7);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    appearance: none;
    box-shadow: unset;
    cursor: pointer;
`

export const QuickSettingsButton = styled.button`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--primary-red-orange-1, #FB2F2F);
    border:1px solid var(--primary-red-orange-1, #FB2F2F);
    color: var(--neutral-gray, #F1F4F7);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    appearance: none;
    box-shadow: unset;
    cursor: pointer;
`

export const QuickSettingsItemsContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    width: 100%;
`

export const QuickSettingsItemsWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    width: 100%;

    & p {
        color: var(--secondary-dark-grey-1, #4B4F5E);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }
`

export const QuickSettingsItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    justify-content: space-between;

    & span {
        color: var(--secondary-dark-1, #0C202A);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
    }
`

export const DashboardAlert = styled.div`
    position: fixed;
    top: 60px;
    left: 0px;
    z-index: 8;
    display: flex;
    width: 100%;
    padding: 8px 24px;
    align-items: center;
    gap: 24px;
    background: rgba(229, 172, 0, 1);
`

export const DashboardAlertWrapper = styled.div`
    width: 100%;
    padding-left: calc(70px + 1rem);
    padding-right: 1rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DashboardAlertText = styled.div`
    color: var(--secondary-dark-1, #0C202A);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`

export const DashboardAlertButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

export const DashboardAlertButtonPrimary = styled(ButtonV2)`
    padding: 8px 24px;
`
export const DashboardAlertButtonDefault = styled(ButtonV2)`
    background: var(--neutral-gray, #F1F4F7);
    border:1px solid var(--neutral-gray, #F1F4F7);
    color: var(--secondary-dark-grey-1, #4B4F5E);
    padding: 8px 24px;
`
export const MoreActionWrapper = styled.div`
@media (max-width: 490px) {
   .mobile {
    display: none;
    opacity: 0;
    transition: all .3s ease-in-out;
   }
}
@media (min-width: 489px) {
    .tablet {
    display: none;
    opacity: 0;
    transition: all .3s ease-in-out;
    }
}
`;
export const TemplateIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4717_42917" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4717_42917)">
                <path d="M8 17H13C13.2833 17 13.5208 16.9042 13.7125 16.7125C13.9042 16.5208 14 16.2833 14 16C14 15.7167 13.9042 15.4792 13.7125 15.2875C13.5208 15.0958 13.2833 15 13 15H8C7.71667 15 7.47917 15.0958 7.2875 15.2875C7.09583 15.4792 7 15.7167 7 16C7 16.2833 7.09583 16.5208 7.2875 16.7125C7.47917 16.9042 7.71667 17 8 17ZM8 13H16C16.2833 13 16.5208 12.9042 16.7125 12.7125C16.9042 12.5208 17 12.2833 17 12C17 11.7167 16.9042 11.4792 16.7125 11.2875C16.5208 11.0958 16.2833 11 16 11H8C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13ZM8 9H16C16.2833 9 16.5208 8.90417 16.7125 8.7125C16.9042 8.52083 17 8.28333 17 8C17 7.71667 16.9042 7.47917 16.7125 7.2875C16.5208 7.09583 16.2833 7 16 7H8C7.71667 7 7.47917 7.09583 7.2875 7.2875C7.09583 7.47917 7 7.71667 7 8C7 8.28333 7.09583 8.52083 7.2875 8.7125C7.47917 8.90417 7.71667 9 8 9ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z" fill="#4B4F5E" />
            </g>
        </svg>
    )
}
export const UploadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4717_42917" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4717_42917)">
                <path d="M8 17H13C13.2833 17 13.5208 16.9042 13.7125 16.7125C13.9042 16.5208 14 16.2833 14 16C14 15.7167 13.9042 15.4792 13.7125 15.2875C13.5208 15.0958 13.2833 15 13 15H8C7.71667 15 7.47917 15.0958 7.2875 15.2875C7.09583 15.4792 7 15.7167 7 16C7 16.2833 7.09583 16.5208 7.2875 16.7125C7.47917 16.9042 7.71667 17 8 17ZM8 13H16C16.2833 13 16.5208 12.9042 16.7125 12.7125C16.9042 12.5208 17 12.2833 17 12C17 11.7167 16.9042 11.4792 16.7125 11.2875C16.5208 11.0958 16.2833 11 16 11H8C7.71667 11 7.47917 11.0958 7.2875 11.2875C7.09583 11.4792 7 11.7167 7 12C7 12.2833 7.09583 12.5208 7.2875 12.7125C7.47917 12.9042 7.71667 13 8 13ZM8 9H16C16.2833 9 16.5208 8.90417 16.7125 8.7125C16.9042 8.52083 17 8.28333 17 8C17 7.71667 16.9042 7.47917 16.7125 7.2875C16.5208 7.09583 16.2833 7 16 7H8C7.71667 7 7.47917 7.09583 7.2875 7.2875C7.09583 7.47917 7 7.71667 7 8C7 8.28333 7.09583 8.52083 7.2875 8.7125C7.47917 8.90417 7.71667 9 8 9ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z" fill="#4B4F5E" />
            </g>
        </svg>
    )
}

export const HeaderV2 = styled.div`
    display: none;
    padding: 12px 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-bottom: 1px solid var(--Secondary-Light-Grey-1, #DFDFDF);
    background: #FFF;
    position: sticky;
    top: 0;
    z-index: 100;
    @media (min-width: 768px) {
        display: flex;
    }
`

export const HeaderV2LeftContainer = styled.div`
display: flex;
align-items: center;
gap: 24px;
flex: 1 0 0;

& img {
    max-width: 40px;
    width: 100%;
    height: auto;
}
`
export const HeaderV2RightContainer = styled.div`
display: flex;
align-items: center;
gap: 16px;
`

export const HeaderV2MenuGroup = styled.div`
display: flex;
align-items: flex-start;
gap: 24px;
`

export const HeaderV2MenuLabel = styled('div')<{active: boolean}>`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;

color: var(--Secondary-Dark-1, #0C202A);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 160% */
cursor: pointer; 

${ props => props.active ? 
    'color: var(--Primary-Red-Orange-1, #FB2F2F)'
    : null
}
`
export const HeaderV2MenuItems = styled.div`
display: flex;
padding: 8px;
align-items: center;
gap: 8px;
cursor: pointer;
`
