import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CommonText } from '@lib/components';
import { CustomerFilter } from './customerBody/customerFilter';
import { CSVExport } from '../../bookings/mobileBookings/modals/csvExport';
import { CustomerToolHeader } from './customerToolHeader';
import { SettingsHeader, SettingsHeaderTextBreadcrumbs, SettingsHeaderTextContainer, SettingsHeaderTextTitle, SettingsHeaderTextWrapper } from '../../settings-v2';
import { CustomerBody } from './customerBody';
import { CustomerFooterWrapper } from './customerBody/style';

export const HEADER_TOP_HEIGHT = 57;

export const HEADER_HEIGHT = 53;

const MobileCustomersWrapper = styled.div`
    /* padding: 16px; */
`;

const CustomerHeader = styled.div`
    position: fixed;
    width: 100%; 
    background: #FEFEFE;
    left: 0;
    top: ${HEADER_TOP_HEIGHT}px;
    padding: 8px 16px;
    z-index: 1;
    @media (min-width: 768px) {
        background: transparent;
        position: static;
        display: flex;
        justify-content: center;
    }
`;

const CustomerBodyWrapper = styled.div<{ customerDetailOpen: boolean, openFilterModal: boolean }>`
    flex-direction: column;
    display: flex;
    gap: 16px;
    position: relative;
    top: 114px;
    height: calc(100vh - 114px);
    overflow-y: auto;
    padding: 1rem 1rem 83px 1rem;
    @media (min-width: 768px) {
        top: 0px;
        padding: 1rem;
        height: ${({ customerDetailOpen, openFilterModal }) => customerDetailOpen ? css`calc(100vh - 130px)` : openFilterModal ? css`calc(100vh - 170px);` : css`calc(100vh - 185px)`}
    }
`;

const EmptySearch = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    height: 80vh;
`;

const CustomersV2 = () => {
    return useObserver(() => {
        const width = window.innerWidth;
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen } = store;
        const { openExportModal, openFilterModal, openCreateCustomer, currentCustomer } = store.customerControl;
        const openCustomerList = !currentCustomer && !openFilterModal && !openExportModal && !openCreateCustomer;
        useEffect(() => {
            handleGetCustomerData();
            store.updateCustomerControl({ currentCustomer: null, openFilterModal: false, openExportModal: false, openCreateCustomer: false })
            store.updateCustomerSearchInfo({
                displayResult: 10,
                sortBy: 'created',
                direction: 'asc',
                type: [],
                searchInfo: ''});
        }, [])

        const handleGetCustomerData = async () => {
            const data = {
                "page": 1,
                "limit": 10,
                "sort": {
                    "created": -1
                },
                "query": {
                    "restaurants": store.restaurant!._id
                }
            }
            const response = await store.api.customers_find(data);
            if (response.outcome)
                throw new Error(response.message);
            store.updateCustomers({
                items: response.items,
                count: response.count,
                page: 1
            });
        }

        return (
            <MobileCustomersWrapper>
                <SettingsHeader>
                    <SettingsHeaderTextContainer>
                        <SettingsHeaderTextWrapper>
                            <SettingsHeaderTextTitle>
                                {openCustomerList && 'Customer'}
                                {openFilterModal && 'Filter'}
                                {openExportModal && 'Export Data'}
                                {currentCustomer && 'Customer Details'}
                                {openCreateCustomer && 'Create Customer'}
                            </SettingsHeaderTextTitle>
                            <SettingsHeaderTextBreadcrumbs style={{ flexDirection: 'row', justifyContent: 'start', gap: 4 }}>
                                <div style={{ cursor: 'pointer' }} onClick={() => { store.router.push("/") }}>Home</div> /
                                <div style={{ cursor: 'pointer' }} onClick={() => { store.router.push(`/restaurant/${store.restaurant!._id}`) }}>Dashboard </div> /
                                {(currentCustomer || openFilterModal || openExportModal || openCreateCustomer) && <div style={{ cursor: 'pointer' }} onClick={() => {
                                    store.router.push(`/restaurant/${store.restaurant!._id}/customers`)
                                    store.updateCustomerControl({ currentCustomer: null, openFilterModal: false, openExportModal: false, openCreateCustomer: false })
                                }}>Customers</div>}
                            </SettingsHeaderTextBreadcrumbs>
                        </SettingsHeaderTextWrapper>
                    </SettingsHeaderTextContainer>

                </SettingsHeader>
                {
                    openCustomerList && !mobileSettingsOpen && (
                        <CustomerHeader>
                            <CustomerToolHeader />
                        </CustomerHeader>
                    )
                }
                <CustomerBodyWrapper customerDetailOpen={!!currentCustomer} openFilterModal={openFilterModal}>
                    {store.customers?.items.length > 0 ?
                        <CustomerBody />
                        : <EmptySearch>
                            <CommonText size="medium" weight={600} color="#4B4F5E">Your search has returned no result</CommonText>
                        </EmptySearch>
                    }

                </CustomerBodyWrapper>
            </MobileCustomersWrapper>
        );
    })

};
export default CustomersV2;