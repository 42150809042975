import React, { useEffect, useState } from 'react';
import { BookingSearch } from './bookingSearch';
import styled from 'styled-components';
import { BookingBody } from './bookingBody';
import { MobXProviderContext, useObserver } from 'mobx-react';
import { CSVExport } from './modals/csvExport';
import { MobileFilter } from './modals/filter';
import { CommonText } from '@lib/components';
import { format } from 'date-fns'
import { BookingBodyWrapper, BookingHeader, EmptySearch, MobileBookingsWrapper } from '../bookings-v2/bookingV2Component';



export const MobileBookings = () => {
    return useObserver(() => {
        const { store } = React.useContext(MobXProviderContext);
        const { mobileSettingsOpen } = store;
        const {searchKey} = store.bookingControl;

        return (
            <MobileBookingsWrapper>
                {
                    !mobileSettingsOpen && (
                        <BookingHeader>
                            <BookingSearch  />
                        </BookingHeader>
                    )
                }
                <BookingBodyWrapper style={{paddingBottom: 57}}>
                    {searchKey && store.bookings.items.length === 0 ?
                        <EmptySearch>
                            <CommonText size="medium" weight={600} color="#4B4F5E">Your search has returned no result</CommonText>
                        </EmptySearch>
                        : <BookingBody />
                    }
                </BookingBodyWrapper>
                <CSVExport isOpen={store.bookingControl.openExportModal} handleClose={() => store.updateBookingControl({ openExportModal: false })}  />
                <MobileFilter isOpen={store.bookingControl.openFilterModal} handleClose={() => store.updateBookingControl({ openFilterModal: false })} />
            </MobileBookingsWrapper>
        );
    })

};
