import React, { useEffect, useState } from 'react';
import {
  Button,
  CommonText,
  Divider,
  FormGroup,
  GroupOptionItems,
  Input,
  Sidebar,
  FooterSection,
  SidebarBodySection,
  SidebarBodySectionTop,
} from '@lib/components';
import styled from 'styled-components';
import { durationOptionsConstant, reasonOptionsConstant, serviceOptionsConstant, weekday } from './constants';
import { RadioCircleCheck } from './radioCircleCheck';
import { deselectAllOptions, updateOptionsUtil } from '../../../../../../utils/pause_services';
import { MobXProviderContext } from 'mobx-react';

export type PauseServicesSidebarProps = {
  isOpen: boolean;
  close: () => void;
  finishCountdown: (value: boolean) => void;
};

const ButtonTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PauseServicesSidebar: React.FC<PauseServicesSidebarProps> = ({ isOpen, close, finishCountdown }) => {
  const [serviceOptions, setServiceOptions] = useState<TOptionItem[]>(serviceOptionsConstant);
  const [durationOptions, setDurationOptions] = useState<TOptionItem[]>(durationOptionsConstant);
  const [reasonOptions, setReasonOptions] = useState<TOptionItem[]>(reasonOptionsConstant);
  const [durationInputVal, setDurationInputVal] = useState('');
  const [reasonInputVal, setReasonInputVal] = useState('');

  const { store } = React.useContext(MobXProviderContext);

  const restaurantId = store.restaurant._id;
  useEffect(() => {
    const adjustButtonPosition = () => {
      const button = document.querySelector('.pause-services');
      if (button) {
        const viewportHeight = window.innerHeight;
        const clientHeight = document.documentElement.clientHeight;
        (button as HTMLElement).style.bottom = `${viewportHeight - clientHeight}px`;
      }
    };

    window.addEventListener('resize', adjustButtonPosition);
    adjustButtonPosition();

    return () => {
      window.removeEventListener('resize', adjustButtonPosition);
    };
  }, []);
  const updateServiceOptions = (id: string) => {
    updateOptionsUtil(id, serviceOptions, setServiceOptions, true);
  };

  const updateDurationOptions = (id: string) => {
    updateOptionsUtil(id, durationOptions, setDurationOptions, false);
    setDurationInputVal("");
  };

  const updateReasonOptions = (id: string) => {
    updateOptionsUtil(id, reasonOptions, setReasonOptions, false);
    setReasonInputVal("");
  };

  const resetFields = () => {
    deselectAllOptions(serviceOptionsConstant, setServiceOptions);
    deselectAllOptions(durationOptionsConstant, setDurationOptions);
    deselectAllOptions(reasonOptionsConstant, setReasonOptions);
    setDurationInputVal("");
    setReasonInputVal("");
  };
  
  const services = serviceOptions.reduce((accumulator, currentValue) => {
    if (currentValue.isActive) {
      accumulator.push(currentValue.value);
    }
    return accumulator;
  }, [] as string[]);
  const selectedDuration = durationOptions.find(item => item.isActive);
  let duration = selectedDuration ? +selectedDuration.value : +durationInputVal;
  const selectedReason = reasonOptions.find(item => item.isActive);
  const reason = selectedReason ? selectedReason.value : reasonInputVal;

  const disabledConfirmBtn = services.length === 0 || !duration || !reason;

  const handleConfirm = async () => {
    if (!disabledConfirmBtn) {

      const timeDiffToClose = store.getTimeDiffToCloseStoreInMiliseconds(store.restaurant);
      let pauseDuration = duration * 1000 * 60;

      if (duration * 1000 * 60 > timeDiffToClose) {
        pauseDuration = timeDiffToClose;
      }

      await store.api.restaurant_update_operation({
        _id: restaurantId,
        status: 'paused',
        duration: pauseDuration,
        services, // add any of the following: "pickup", "delivery", "dine_in", "booking"
        reason,
      });
      resetFields();
      close();
      const endTime = Date.now() + pauseDuration
      store.updateTempararyPauseEnd(endTime);
      await store.service.restaurant.get();
      finishCountdown(false);
    }
  };
  const onDurationChange = (e: any) => {
    const value = parseFloat(e.target.value);
    if (value >= 0 || e.target.value === '') {
      setDurationInputVal(e.target.value);
      if (e.target.value.length === 1) {
        deselectAllOptions(durationOptionsConstant, setDurationOptions);
      }
    }
  };

  const onReasonChange = (e: any) => {
    setReasonInputVal(e.target.value);
    if (e.target.value.length === 1) {
      deselectAllOptions(reasonOptionsConstant, setReasonOptions);
    }
  };
  

  return (
    <Sidebar isOpen={isOpen} headerTitle="Pause Services" width={420} onClose={close}>
      <SidebarBodySection style={{padding: '16px', height: '100%'}}>
        <SidebarBodySectionTop>
          <CommonText size="small" weight={500} color="#0C202A">
            Set services you want to pause and how many minutes
          </CommonText>
          <CommonText size="semiMedium" weight={600} color="#4B4F5E">
            Service:
          </CommonText>
          <GroupOptionItems optionItems={serviceOptions} updateOptions={updateServiceOptions} />
          <Divider />
          <CommonText size="semiMedium" weight={600} color="#4B4F5E">
            Duration:
          </CommonText>
          <GroupOptionItems optionItems={durationOptions} updateOptions={updateDurationOptions} />
          <FormGroup className="m-b-0" small_title={true} no_border={true}>
            <Input
              placeholder={`Others (Type in minues)`}
              required={true}
              type="number"
              step={0.01}
              style={{
                marginBottom: '16px',
                fontFamily: 'Inter',
              }}
              value={durationInputVal}
              onChange={onDurationChange}
              className="no-round"
            />
          </FormGroup>
          <Divider />
          <CommonText size="semiMedium" weight={600} color="#4B4F5E">
            Reason:
          </CommonText>
          <GroupOptionItems optionItems={reasonOptions} updateOptions={updateReasonOptions} />
          <FormGroup className="m-b-5" small_title={true} no_border={true}>
            <Input
              placeholder={`Others`}
              step={0.01}
              style={{
                marginBottom: '16px',
                fontFamily: 'Inter',
              }}
              value={reasonInputVal}
              onChange={onReasonChange}
              className="no-round"
            />
          </FormGroup>
        </SidebarBodySectionTop>
      </SidebarBodySection>
      <FooterSection width={window.innerWidth < 420 ? window.innerWidth : 420} version="v2" style={{ position: 'sticky', bottom: 0}}>
          <Button className='pause-services' full={true} type="button" color="primary" onClick={handleConfirm} disabled={disabledConfirmBtn}>
            <ButtonTextWrapper>
              <RadioCircleCheck />
              Confirm
            </ButtonTextWrapper>
          </Button>
        </FooterSection>
    </Sidebar>
  );
};
